export default {
  "row": "qxF body-1",
  "active": "qxg",
  "cell": "qxD",
  "empty": "qxV",
  "ellipsis": "qxL",
  "text-secondary": "qxT",
  "cell-type": "qxz",
  "cell-reason": "qxa",
  "cell-amount": "qxH",
  "row-sidebar": "qxO",
  "animated": "qxm",
  "fadein-item": "qxp",
  "animated-cell": "qxx"
};
