export default {
  "header-cell": "qeE",
  "header-content": "qed caption-bold",
  "active": "qeI",
  "empty": "qet",
  "align-right": "qeM",
  "request-type": "qeP",
  "requester": "qel",
  "amount": "qeX"
};
