export default {
  "referral-invite-new": "cGk",
  "referral": "cGJ",
  "center": "cGs",
  "illustration": "cGF",
  "share-referral": "cGg",
  "choice": "cGD",
  "line": "cGV",
  "email": "cGL",
  "email-input": "cGT",
  "input-field--error": "cGz",
  "error-message": "cGa",
  "external-link-icon": "cGH"
};
