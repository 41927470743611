export default {
  "insight-pill": "qCo",
  "interactive": "qCn",
  "focus": "qCi",
  "subtitle": "qCA caption",
  "hidden": "qCY",
  "missing-amounts-warning": "qCh",
  "totals": "qCf",
  "counter": "qCK title-3",
  "loading": "qCG",
  "error": "qCr",
  "title": "qCb title-4"
};
