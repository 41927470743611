export default {
  "container": "chr",
  "form-container": "chb",
  "content": "chy",
  "fields": "chU",
  "input-settings": "chj",
  "current-year": "chW",
  "next-invoice-number": "chu",
  "preview-container": "cfS",
  "actions": "cfc",
  "close-button": "cfq btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cfZ",
  "preview-image": "cfR"
};
