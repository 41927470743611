export default {
  "header-cell": "cuq",
  "empty": "cuZ",
  "header-content": "cuR caption-bold",
  "active": "cuQ",
  "header-type": "cue",
  "header-reason": "cuB",
  "header-status": "cuE",
  "header-amount": "cud",
  "row-sidebar": "cuI",
  "hidden": "cut",
  "animated": "cuM",
  "fadein-item": "cuP",
  "animated-cell": "cul"
};
