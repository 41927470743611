export default {
  "item-status": "cmg",
  "item-date": "cmD",
  "item-amount": "cmV body-1",
  "item-amount-credit": "cmL",
  "row": "cmT",
  "icon": "cmz",
  "disabled": "cma",
  "icon-container": "cmH",
  "text-container": "cmO",
  "text-row": "cmm",
  "item-details": "cmp",
  "counterparty-name": "cmx",
  "column": "cmw",
  "item-amount-disabled": "cmN",
  "attachment-tooltip": "cmv",
  "bank-account-avatar": "cmo"
};
