export default {
  "custom-period-wrapper": "cHm",
  "fadeIn": "cHp",
  "custom-period-description": "cHx body-2",
  "header": "cHw",
  "arrow-left": "cHN",
  "body": "cHv",
  "period-wrapper": "cHo",
  "back-button": "cHn",
  "footer": "cHi"
};
