export default {
  "header": "qRU",
  "header-type": "qRj",
  "close": "qRW",
  "close-icon": "qRu",
  "header-infos": "qQS",
  "title": "qQc body-1",
  "declined": "qQq",
  "canceled": "qQZ",
  "approved": "qQR",
  "date": "qQQ body-2",
  "container": "qQe",
  "picto": "qQB",
  "request": "qQE",
  "picto-status": "qQd",
  "check": "qQI",
  "stop": "qQt",
  "warning": "qQM",
  "general": "qQP",
  "amount": "qQl",
  "counterparty": "qQX body-2",
  "initiator": "qQC",
  "initiator-avatar": "qQk",
  "avatar-image": "qQJ",
  "initiator-id": "qQs",
  "name": "qQF body-2",
  "role": "qQg caption-bold",
  "infobox": "qQD",
  "account-infobox": "qQV qQD",
  "beneficiary-warning": "qQL caption-bold",
  "dates-wrapper": "qQT",
  "dates": "qQz",
  "recurrence": "qQa caption-bold",
  "arrow": "qQH",
  "notify-checkbox": "qQO"
};
