export default {
  "modal": "qkD",
  "action-container": "qkV",
  "similar-label": "qkL",
  "info-icon": "qkT",
  "container": "qkz",
  "subtitle": "qka caption-bold",
  "error": "qkH",
  "item-container": "qkO"
};
