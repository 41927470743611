export default {
  "review": "qIg",
  "review-content": "qID",
  "review-parameters": "qIV",
  "row": "qIL",
  "row__border": "qIT",
  "label": "qIz",
  "value": "qIa",
  "error": "qIH",
  "tooltip-icon": "qIO",
  "terms-link": "qIm"
};
