export default {
  "name-wrapper": "cxq",
  "preset-name": "cxZ",
  "invoices-entry-period-container": "cxR",
  "transactions-entry-period-container": "cxQ",
  "period-wrapper": "cxe",
  "period-btn": "cxB",
  "cancel-placeholder": "cxE",
  "period-cancel-btn": "cxd",
  "transactions-entry-container": "cxI",
  "invoices-entry-container": "cxt",
  "dropdown": "cxM",
  "dropdown-opened": "cxP",
  "dropdown-closed": "cxl"
};
