export default {
  "header": "cjP",
  "header-type": "cjl",
  "close": "cjX",
  "close-icon": "cjC",
  "header-infos": "cjk",
  "title": "cjJ body-1",
  "declined": "cjs",
  "canceled": "cjF",
  "approved": "cjg",
  "pending": "cjD",
  "date": "cjV body-2",
  "container": "cjL",
  "picto": "cjT",
  "picto-status": "cjz",
  "general": "cja",
  "amount": "cjH",
  "counterparty": "cjO body-2",
  "initiator": "cjm",
  "initiator-avatar": "cjp",
  "avatar-image": "cjx",
  "initiator-id": "cjw",
  "name": "cjN body-2",
  "role": "cjv caption-bold",
  "infobox": "cjo",
  "account-infobox": "cjn cjo",
  "beneficiary-warning": "cji caption-bold",
  "dates-wrapper": "cjA",
  "dates": "cjY",
  "recurrence": "cjh caption-bold",
  "arrow": "cjf",
  "notify-checkbox": "cjK"
};
