export default {
  "row": "qgG",
  "icon": "qgr",
  "cell": "qgb",
  "cell-date": "qgy",
  "cell-amount": "qgU",
  "cell-status": "qgj",
  "cell-content": "qgW",
  "align-right": "qgu"
};
