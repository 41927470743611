/* import __COLOCATED_TEMPLATE__ from './decline.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class ReimbursementsBulkModalsDeclineComponent extends Component {
  @service errors;
  @service intl;

  @tracked hasEmptyDeclinedNoteError = false;
  @tracked declinedNote;

  textAreaField = TextAreaField;

  get subtitle() {
    let { requests } = this.args.data;

    let uniqueInitiators = [
      ...new Set(requests.map(request => request.get('initiator.membershipId'))),
    ];
    return uniqueInitiators.length > 1
      ? this.intl.t('requests.modals.decline-request.subtitle-multiple-requesters')
      : this.intl.t('requests.modals.decline-request.subtitle-single-requester', {
          count: requests.count,
        });
  }

  approveTask = dropTask(async () => {
    this.hasEmptyDeclinedNoteError = false;
    let {
      close,
      data: { approveTask, requests, selector },
    } = this.args;

    if (!this.declinedNote) {
      return (this.hasEmptyDeclinedNoteError = true);
    }

    try {
      if (approveTask) {
        await approveTask.linked().perform(close, requests, this.declinedNote, selector);
      } else {
        close('approve');
      }
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}
