export default {
  "navigation-dropdown": "cLL",
  "expanded": "cLT",
  "trigger": "cLz",
  "trigger-title-icon": "cLa",
  "collapsed": "cLH",
  "responsive": "cLO",
  "logo": "cLm",
  "active": "cLp",
  "trigger-title": "cLx",
  "content": "cLw",
  "content-container": "cLN",
  "dropdown-icon": "cLv",
  "link": "cLo"
};
