export default {
  "mileage-validation": "cyp",
  "actions": "cyx",
  "action-btn": "cyw",
  "action-btn-success": "cyN",
  "action-btn-right": "cyv",
  "request-mileage-validation": "cyo",
  "account-selector": "cyn",
  "has-error": "cyi",
  "funds-disclaimer": "cyA",
  "account-options": "cyY",
  "account-balance": "cyh",
  "warnings": "cyf"
};
