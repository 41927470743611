export default {
  "header-cell": "cUc",
  "empty": "cUq",
  "header-bulk-checkbox": "cUZ",
  "header-type": "cUR",
  "header-requester": "cUQ",
  "header-date": "cUe",
  "header-amount": "cUB",
  "header-approval": "cUE",
  "header-content": "cUd caption-bold",
  "active": "cUI",
  "header-receipt": "cUt",
  "row-compact": "cUM",
  "row-sidebar": "cUP",
  "hidden": "cUl",
  "animated": "cUX",
  "fadein-item": "cUC",
  "animated-cell": "cUk",
  "checkbox": "cUJ"
};
