export default {
  "item": "qkm",
  "details": "qkp",
  "flex": "qkx",
  "header": "qkw qkx",
  "flex-no-gap": "qkN qkx",
  "file-name": "qkv",
  "label": "qko body-2",
  "self-icon": "qkn",
  "with-separator": "qki",
  "dimmed": "qkA"
};
