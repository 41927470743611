export default {
  "company-logo": "cLf",
  "list-item": "cLK",
  "link": "cLG",
  "title-wrapper": "cLr",
  "title": "cLb body-2",
  "subtitle": "cLy caption",
  "truncated-text": "cLU",
  "image-placeholder": "cLj"
};
