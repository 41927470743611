export default {
  "row": "cuX",
  "active": "cuC",
  "dot": "cuk",
  "red": "cuJ",
  "green": "cus",
  "yellow": "cuF",
  "status": "cug",
  "align-right": "cuD",
  "animated": "cuV",
  "fadein-item": "cuL",
  "cell": "cuT body-2",
  "cell-content": "cuz",
  "amount": "cua body-2",
  "subtitle": "cuH caption",
  "padding-left": "cuO",
  "padding-right": "cum",
  "note": "cup"
};
