export default {
  "wrapper": "cGv",
  "tabs": "cGo",
  "tabs-nav": "cGn",
  "tabs-panel": "cGi",
  "tabs-panel-wrapper": "cGA",
  "list-head": "cGY",
  "list": "cGh",
  "footer": "cGf",
  "reward-amount": "cGK",
  "rewarded": "cGG",
  "registered": "cGr",
  "disclaimer": "cGb",
  "empty-state-wrapper": "cGy",
  "empty-state": "cGU",
  "illustration": "cGj",
  "spinner": "cGW",
  "total-earned": "cGu"
};
