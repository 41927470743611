export default {
  "container": "qTA",
  "header": "qTY",
  "title": "qTh caption-bold",
  "search-icon": "qTf",
  "text": "qTK body-2",
  "trigger": "qTG",
  "loading-state": "qTr",
  "dropzone-placeholder-wrapper": "qTb",
  "dropzone-placeholder": "qTy",
  "file-loading": "qTU"
};
