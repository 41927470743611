/* import __COLOCATED_TEMPLATE__ from './date-picker-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import isFunction from 'qonto/utils/is-function';

export default class TransfersInternationalOutDynamicFormDatePickerComponent extends Component {
  get shouldRefreshOnChange() {
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onUpdate(date) {
    let { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, date);

    if (this.shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}
