export default {
  "row": "cbl",
  "active": "cbX",
  "animated": "cbC",
  "fadein-item": "cbk",
  "cell": "cbJ",
  "cell-content": "cbs",
  "row-sidebar": "cbF",
  "empty": "cbg",
  "cell-amount": "cbD",
  "subtitle": "cbV caption",
  "hidden": "cbL",
  "cell-approve": "cbT",
  "quick-actions": "cbz",
  "account-select": "cba",
  "note": "cbH",
  "cell-content-amount": "cbO body-1",
  "cell-status": "cbm"
};
