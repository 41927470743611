export default {
  "header-cell": "cbQ",
  "header-content": "cbe caption-bold",
  "active": "cbB",
  "empty": "cbE",
  "request-type": "cbd",
  "date": "cbI",
  "requester": "cbt",
  "amount": "cbM",
  "header-status": "cbP"
};
