export default {
  "banner": "czV",
  "inner": "czL",
  "header": "czT title-3",
  "content": "czz",
  "subtitle": "cza body-1",
  "list": "czH",
  "item": "czO body-1",
  "icon": "czm",
  "cta": "czp btn btn--primary",
  "spinner": "czx",
  "illustration": "czw",
  "image": "czN"
};
