export default {
  "header-cell": "qaN",
  "header-content": "qav caption-bold",
  "active": "qao",
  "align-right": "qan",
  "empty": "qai",
  "align-checkbox": "qaA",
  "col-5": "qaY",
  "col-9": "qah",
  "emitted-at-header-tag": "qaf"
};
