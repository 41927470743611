export default {
  "section": "cho",
  "header-section": "chn",
  "header-title": "chi",
  "header-item": "chA",
  "item": "chY",
  "center-align": "chh",
  "content-gap": "chf",
  "cta-footer": "chK",
  "section-content": "chG"
};
