export default {
  "team-card": "qDI",
  "card-link": "qDt",
  "shadow": "qDM",
  "history-button": "qDP",
  "cards-button": "qDl",
  "menu-item": "qDX",
  "subtitle": "qDC",
  "delete-team": "qDk"
};
