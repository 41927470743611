export default {
  "wrapper": "qHi",
  "title": "qHA",
  "activity-tag": "qHY",
  "select": "qHh",
  "tooltip": "qHf",
  "date-picker": "qHK",
  "internal-notes": "qHG",
  "scheduled": "qHr"
};
