export default {
  "invoice-sidebar": "cYN",
  "body": "cYv",
  "box": "cYo",
  "box-header": "cYn",
  "footer": "cYi",
  "footer-button": "cYA",
  "footer-button-tooltip": "cYY",
  "sidebar-box": "cYh",
  "border-top": "cYf",
  "row": "cYK",
  "status-container": "cYG",
  "due-date-container": "cYr",
  "greyed-out": "cYb",
  "struck-through": "cYy",
  "overdue": "cYU",
  "primary-actions": "cYj cYh",
  "primary-action": "cYW",
  "danger-action": "cYu",
  "button-icon": "chS",
  "button-label": "chc body-2",
  "related-credit-note-title": "chq caption-bold",
  "paid-at-placeholder": "chZ",
  "collapsible-timeline": "chR",
  "timeline-status-label": "chQ"
};
