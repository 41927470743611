export default {
  "beneficiary-details": "qxh",
  "beneficiary-account-types": "qxf",
  "divider": "qxK",
  "label": "qxG",
  "labels": "qxr",
  "error": "qxb",
  "link": "qxy caption",
  "sticky-panel": "qxU"
};
