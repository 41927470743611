export default {
  "card": "cnA",
  "error": "cnY",
  "customer-info": "cnh",
  "edit-customer-tooltip": "cnf",
  "title": "cnK",
  "flex-row": "cnG",
  "dot-separator": "cnr",
  "flex-span": "cnb",
  "faq-link": "cny",
  "tooltip-wrapper": "cnU",
  "badge-union": "cnj"
};
