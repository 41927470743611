export default {
  "row": "qHs",
  "col": "qHF",
  "header": "qHg",
  "block": "qHD",
  "col-5": "qHV",
  "col-6": "qHL",
  "col-9": "qHT",
  "col-12": "qHz",
  "hide": "qHa"
};
