export default {
  "vat-lines": "qvV",
  "vat-line": "qvL",
  "vat-input": "qvT",
  "vat-rate": "qvz",
  "vat-option-button": "qva",
  "disabled": "qvH",
  "vat-option-tooltip": "qvO",
  "remove-label": "qvm",
  "remove-button": "qvp",
  "vat-total": "qvx",
  "vat-total-amount": "qvw",
  "add-vat-line": "qvN",
  "with-vat-lines": "qvv"
};
