export default {
  "insight-pill": "qCy",
  "insight-pill-figure": "qCU",
  "insight-pill-renderer": "qCj",
  "loading": "qCW",
  "counter": "qCu",
  "error": "qkS",
  "insight-pill-label": "qkc",
  "insight-pill-subtitle": "qkq caption",
  "hidden": "qkZ",
  "missing-amounts-warning": "qkR",
  "missing-amounts": "qkQ"
};
