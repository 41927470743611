export default {
  "modal": "qVd",
  "section": "qVI",
  "header": "qVt",
  "title": "qVM",
  "separator-title": "qVP",
  "icon": "qVl",
  "formats": "qVX",
  "columns": "qVC",
  "column-list": "qVk",
  "draggable-column": "qVJ",
  "column-field": "qVs",
  "column-selector": "qVF",
  "column-with-error": "qVg",
  "handle": "qVD",
  "label-color": "qVV",
  "disclaimer": "qVL"
};
