export default {
  "row": "qNy",
  "col": "qNU",
  "counterparty": "qNj",
  "header": "qNW",
  "icon": "qNu",
  "col-12": "qvS",
  "col-8": "qvc",
  "col-6": "qvq",
  "col-4": "qvZ",
  "col-2": "qvR",
  "hide": "qvQ"
};
