/* import __COLOCATED_TEMPLATE__ from './unarchive-popup.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class UnarchivePopup extends Component {
  @service intl;
  @service networkManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service supplierInvoicesInsights;

  unarchiveTask = dropTask(async close => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_unarchive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: [this.args.data.id],
          }),
        }
      );

      this.router.transitionTo('supplier-invoices', {
        queryParams: { status: INVOICE_STATUSES.toReview },
      });
      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.unarchive.toast.success')
      );

      this.supplierInvoicesInsights.fetchInsights().catch(() => {});
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });
}
