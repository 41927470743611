export default {
  "transfer-form": "qOu",
  "transfer-form-title": "qmS",
  "transfer-form-subtitle": "qmc",
  "form": "qmq",
  "form-footer": "qmZ",
  "footer-btn": "qmR",
  "header": "qmQ",
  "main-settings": "qme",
  "additional-settings": "qmB",
  "form-container": "qmE"
};
