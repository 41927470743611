/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TransactionsFiltersComponent extends Component {
  @tracked activeFilterId;
  @tracked selectedPreset = this.args.selectedPreset;
  @tracked cachedFormData = [];

  @action
  onSelectPreset(preset, id) {
    this.selectedPreset = preset;
    this.updateActiveFilterId(id);

    this.cachedFormData = [];
  }

  @action
  applyFilters(query, id) {
    this.selectedPreset = undefined;
    this.updateActiveFilterId(id);

    this.args.applyFilters(query);
  }

  @action
  clearFilters() {
    this.updateActiveFilterId();
    this.selectedPreset = undefined;
    this.cachedFormData = [];

    this.args.applyFilters();
  }

  @action
  refreshCache(formData, isUpdating = false) {
    let filteredCache = this.cachedFormData.filter(({ id }) => id !== formData.id);
    this.cachedFormData = isUpdating ? [...filteredCache, formData] : filteredCache;
  }

  @action
  updateActiveFilterId(id = null) {
    this.activeFilterId = id;
  }
}
