export default {
  "header-cell": "qeV",
  "header-content": "qeL caption-bold",
  "active": "qeT",
  "empty": "qez",
  "request-type": "qea",
  "status": "qeH",
  "note": "qeO",
  "amount": "qem"
};
