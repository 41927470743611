/* import __COLOCATED_TEMPLATE__ from './nrc-payment.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

export default class NrcPaymentHeader extends Component {
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  @service intl;

  get subject() {
    return this.args.highlightedItem.subject;
  }

  get nrc() {
    return this.subject.get('nrc');
  }

  get modeloDescription() {
    let taxCode = this.subject.get('taxCode');
    let taxDescription = this.subject.get('taxDescription');
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${taxCode} - ${taxDescription}`;
  }
}
