export default {
  "wrapper": "qEI",
  "beneficiary": "qEt",
  "name": "qEM body-1",
  "beneficiary-email": "qEP body-2",
  "dropdown-icon": "qEl",
  "trusted": "qEX",
  "account-number": "qEC flex body-2",
  "account-icon": "qEk",
  "m-chip": "qEJ",
  "dropdown": "qEs body-2",
  "delete-option": "qEF",
  "beneficiary-name": "qEg"
};
