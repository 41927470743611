export default {
  "row": "cUs body-1",
  "active": "cUF",
  "cell": "cUg",
  "empty": "cUD",
  "ellipsis": "cUV",
  "text-secondary": "cUL",
  "transfer-type": "cUT",
  "transfer-type__icon": "cUz",
  "quick-actions": "cUa",
  "amount": "cUH",
  "cell-approve": "cUO",
  "account-select": "cUm",
  "row-compact": "cUp",
  "cell-amount": "cUx",
  "cell-content-receipt": "cUw body-1",
  "cell-date": "cUN",
  "row-sidebar": "cUv",
  "hidden": "cUo",
  "animated": "cUn",
  "fadein-item": "cUi",
  "animated-cell": "cUA",
  "note": "cUY",
  "checkbox": "cUh"
};
