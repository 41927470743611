export default {
  "iban": "qBi",
  "checkbox": "qBA",
  "toggle-content": "qBY",
  "beneficiary-name": "qBh",
  "category": "qBf",
  "beneficiary-email": "qBK",
  "beneficiary-form": "qBG",
  "beneficiary-email-container": "qBr"
};
