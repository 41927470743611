export default {
  "row": "czS",
  "terminated": "czc",
  "status-avatar": "czq",
  "amount": "czZ body-1",
  "item-info": "czR",
  "date-cell": "czQ",
  "cell": "cze body-2",
  "status-icon": "czB",
  "amount-cell": "czE",
  "item-name-cell": "czd",
  "item-name-container": "czI"
};
