export default {
  "refunds-total": "qXd",
  "switch-recurrence": "qXI",
  "flex-wrapper": "qXt",
  "promo-code": "qXM",
  "promo-code-total": "qXP",
  "others": "qXl",
  "others-total": "qXX",
  "subscription-detail": "qXC",
  "total-vat": "qXk",
  "fee-description": "qXJ"
};
