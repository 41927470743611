export default {
  "progress-container": "qDH",
  "dashed-line": "qDO",
  "solid-line": "qDm",
  "circle-container": "qDp",
  "circle": "qDx",
  "current-step-dot": "qDw",
  "last-step-dot": "qDN",
  "success-icon": "qDv",
  "rejected-icon": "qDo"
};
