export default {
  "modal": "qtz",
  "container": "qta",
  "form-container": "qtH",
  "preview-container": "qtO",
  "preview-content": "qtm",
  "switch-container": "qtp",
  "supplier-invoice-preview": "qtx",
  "header": "qtw",
  "footer": "qtN",
  "form-content": "qtv",
  "form-content-section": "qto",
  "form-disclaimer": "qtn",
  "hidden": "qti"
};
