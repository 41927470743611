export default {
  "row": "qPA",
  "disabled": "qPY",
  "cell": "qPh body-2",
  "cell-link": "qPf",
  "cell-content": "qPK",
  "item-truncate-text": "qPG",
  "item-name-cell": "qPr",
  "item-account-cell": "qPb",
  "icon": "qPy",
  "icon-download": "qPU"
};
