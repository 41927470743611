export default {
  "wrapper": "cmn",
  "header": "cmi",
  "fadeIn": "cmA",
  "header-title": "cmY title-4",
  "transactions-container": "cmh",
  "placeholder-title": "cmf",
  "transaction-item-list-container": "cmK",
  "transaction-item-list": "cmG",
  "item": "cmr"
};
