export default {
  "request-expense-report-validation": "qZN",
  "account-selector": "qZv",
  "has-error": "qZo",
  "actions": "qZn",
  "action-btn": "qZi",
  "action-btn-success": "qZA",
  "warnings": "qZY",
  "top-border": "qZh",
  "account-selector-subtitle": "qZf",
  "action-btn-right": "qZK",
  "funds-disclaimer": "qZG",
  "account-options": "qZr",
  "account-balance": "qZb"
};
