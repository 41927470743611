export default {
  "row": "cbp",
  "icon": "cbx",
  "cell": "cbw",
  "cell-amount": "cbN",
  "cell-status": "cbv",
  "cell-date": "cbo",
  "cell-content": "cbn",
  "align-right": "cbi"
};
