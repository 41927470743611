export default {
  "header": "cWP",
  "header-type": "cWl",
  "close": "cWX",
  "close-icon": "cWC",
  "header-infos": "cWk",
  "title": "cWJ body-1",
  "declined": "cWs",
  "canceled": "cWF",
  "approved": "cWg",
  "pending": "cWD",
  "date": "cWV body-2",
  "mileage-icon": "cWL",
  "distance-subtitle": "cWT body-2",
  "link-icon": "cWz",
  "container": "cWa",
  "picto": "cWH",
  "general": "cWO",
  "amount": "cWm",
  "calculation-container": "cWp",
  "counterparty": "cWx body-2",
  "infobox": "cWw"
};
