export default {
  "sidebar-content": "qHu",
  "section": "qOS",
  "header-wrapper": "qOc",
  "transfer-id": "qOq caption",
  "small-padding": "qOZ",
  "title": "qOR",
  "subtitle": "qOQ",
  "actions": "qOe"
};
