export default {
  "button-wrapper": "qLW",
  "btn-filter": "qLu",
  "name-wrapper": "qTS",
  "preset-name": "qTc",
  "cancel-placeholder": "qTq",
  "cancel-button": "qTZ",
  "dropdown-opened": "qTR",
  "dropdown-closed": "qTQ",
  "dropdown-menu": "qTe",
  "loading-item": "qTB",
  "placeholder": "qTE",
  "list-body": "qTd",
  "list-item": "qTI",
  "error-state": "qTt body-2",
  "warning": "qTM",
  "warning-icon": "qTP",
  "footer": "qTl",
  "add-icon": "qTX",
  "empty-state": "qTC"
};
