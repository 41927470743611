export default {
  "row": "qBQ",
  "active": "qBe",
  "dot": "qBB",
  "red": "qBE",
  "green": "qBd",
  "yellow": "qBI",
  "status": "qBt",
  "align-right": "qBM",
  "animated": "qBP",
  "fadein-item": "qBl",
  "cell": "qBX body-2",
  "cell-content": "qBC",
  "amount": "qBk body-2",
  "subtitle": "qBJ caption",
  "padding-left": "qBs",
  "padding-right": "qBF",
  "note": "qBg"
};
