export default {
  "form": "qCd",
  "form-section": "qCI",
  "form-title": "qCt title2",
  "divider": "qCM",
  "row": "qCP",
  "col": "qCl",
  "actions": "qCX",
  "buttons": "qCC",
  "collapsible-timeline": "qCk",
  "timeline-status-label": "qCJ"
};
