import { template as template_2440aa89e0574e65a335bda550fe47e5 } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FilterSelect from '@qonto/ui-kit/components/filter-select';
import { t } from 'ember-intl';
import { variation } from 'ember-launch-darkly';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import ReactBridge from 'qonto/components/react-bridge';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import { DUE_DATE_FILTER, FILTERS, INVOICE_STATUSES, TABS } from 'qonto/constants/supplier-invoice';
import { MultiSelectFilter } from 'qonto/react/components/supplier-invoices/multi-select-filter';
import { ToggleFilter } from 'qonto/react/components/supplier-invoices/toggle-filter';
import SuppliersFilter from '../suppliers/filter';
import styles from './filters.strict-module.css';
const PAYMENT_DATE_FILTER_OPTIONS = {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    THIS_WEEK: 'this_week',
    PAST_WEEK: 'past_week',
    THIS_MONTH: 'this_month',
    PAST_MONTH: 'past_month'
};
const FILTER_TAB_MAP = {
    [TABS.TASKS]: [],
    [TABS.ALL_INVOICES]: [
        FILTERS.SUPPLIERS,
        FILTERS.MULTI_STATUS,
        FILTERS.TO_REVIEW
    ],
    [TABS.TO_PAY]: [
        FILTERS.SUPPLIERS,
        FILTERS.TO_REVIEW,
        FILTERS.DUE_DATE
    ],
    [TABS.SCHEDULED]: [
        FILTERS.SUPPLIERS
    ],
    [TABS.COMPLETED]: [
        FILTERS.SUPPLIERS,
        FILTERS.ARCHIVED_PAID_STATUS,
        FILTERS.PAYMENT_DATE,
        FILTERS.NO_MATCHED_TRANSACTIONS
    ]
};
const TO_REVIEW_FILTER_OPTIONS = {
    DUE_AND_OVERDUE: 'due_and_overdue',
    DUPLICATES: 'duplicates',
    NON_FINANCIAL_DOCUMENT: 'non_financial_documents',
    MISSING_DATA: 'missing_data',
    NO_MATCHED_TRANSACTIONS: 'no_matched_transactions'
};
export default class SupplierInvoicesFilters extends Component {
    @service
    intl;
    @service
    abilities;
    get filters() {
        return FILTER_TAB_MAP[this.args.tab] || [];
    }
    get showSuppliersFilter() {
        return (variation('feature--boolean-supplier-hub') && this.abilities.can('view supplier') && this.args.suppliers.length > 0);
    }
    get paidArchiveStatusOptions() {
        return [
            {
                code: INVOICE_STATUSES.paid,
                value: this.intl.t('supplier-invoices.preview.status.paid')
            },
            {
                code: INVOICE_STATUSES.archived,
                value: this.intl.t('supplier-invoices.preview.status.archived')
            }
        ];
    }
    get selectedPaymentDate() {
        return this.paymentDateFilterOptions.find(({ code })=>code === this.args.paymentDate);
    }
    get paymentDateFilterOptions() {
        return [
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.TODAY,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.today')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.YESTERDAY,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.yesterday')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.THIS_WEEK,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.this-week')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.PAST_WEEK,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.last-week')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.THIS_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.this-month')
            },
            {
                code: PAYMENT_DATE_FILTER_OPTIONS.PAST_MONTH,
                value: this.intl.t('supplier-invoices.filters.payment-date.value.last-month')
            }
        ];
    }
    get selectedStatus() {
        return this.paidArchiveStatusOptions.find((opt)=>opt.code === this.args.status);
    }
    get multiStatusFilterOptions() {
        return [
            {
                value: INVOICE_STATUSES.toReview,
                text: this.intl.t('supplier-invoices.filters.status.value.to-pay')
            },
            {
                value: INVOICE_STATUSES.scheduled,
                text: this.intl.t('supplier-invoices.filters.status.value.scheduled')
            },
            {
                value: INVOICE_STATUSES.paid,
                text: this.intl.t('supplier-invoices.filters.status.value.paid')
            },
            {
                value: INVOICE_STATUSES.archived,
                text: this.intl.t('supplier-invoices.filters.status.value.archived')
            }
        ];
    }
    get toReviewFilterOptions() {
        let isAllInvoicesTab = this.args.tab === TABS.ALL_INVOICES;
        return [
            isAllInvoicesTab ? {
                code: TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE,
                value: this.intl.t('supplier-invoices.filters.to-review.value.due-and-overdue')
            } : false,
            {
                code: TO_REVIEW_FILTER_OPTIONS.DUPLICATES,
                value: this.intl.t('supplier-invoices.filters.to-review.value.duplicates')
            },
            {
                code: TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT,
                value: this.intl.t('supplier-invoices.filters.to-review.value.unrecognized')
            },
            {
                code: TO_REVIEW_FILTER_OPTIONS.MISSING_DATA,
                value: this.intl.t('supplier-invoices.filters.to-review.value.missing-data')
            },
            isAllInvoicesTab ? {
                code: TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS,
                value: this.intl.t('supplier-invoices.filters.to-review.value.no-matched-transactions')
            } : false
        ].filter(Boolean);
    }
    get selectedStatuses() {
        return this.multiStatusFilterOptions.filter((opt)=>this.args.status?.split(',').includes(opt.value));
    }
    get dueAndOverdueSelected() {
        return this.dueAndOverdueOptions.find(({ code })=>code === this.args.dueDate);
    }
    get dueAndOverdueOptions() {
        return [
            {
                code: DUE_DATE_FILTER.PAST_AND_TODAY,
                value: this.intl.t('supplier-invoices.imported.filter.option.due')
            },
            {
                code: DUE_DATE_FILTER.FUTURE,
                value: this.intl.t('supplier-invoices.imported.filter.option.upcoming')
            }
        ];
    }
    get multiStatusFilterPlaceholder() {
        let placeholder = this.intl.t('supplier-invoices.filters.status.placeholder');
        if (this.selectedStatuses.length > 0) {
            return `${placeholder} (${this.selectedStatuses.length})`;
        }
        return placeholder;
    }
    get toReviewSelected() {
        let { noMatchedTransactionsActive, documentType, hasMissingData, dueDate, tab } = this.args;
        let isAllInvoicesTab = tab === TABS.ALL_INVOICES;
        if (isAllInvoicesTab && noMatchedTransactionsActive) {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS);
        }
        if (documentType) {
            return this.toReviewFilterOptions.find(({ code })=>code === documentType);
        }
        if (hasMissingData) {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.MISSING_DATA);
        }
        if (isAllInvoicesTab && dueDate === 'past_and_today') {
            return this.toReviewFilterOptions.find(({ code })=>code === TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE);
        }
    }
    @action
    selectDueAndOverdue(selected) {
        this.args.updateDueDate(selected?.code ?? null);
    }
    @action
    selectToReviewOption(selected) {
        this.resetToReviewFilter();
        switch(selected?.code){
            case TO_REVIEW_FILTER_OPTIONS.DUE_AND_OVERDUE:
                {
                    this.args.updateDueDate('past_and_today');
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.DUPLICATES:
            case TO_REVIEW_FILTER_OPTIONS.NON_FINANCIAL_DOCUMENT:
                {
                    this.args.updateDocumentType(selected.code);
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.MISSING_DATA:
                {
                    this.args.updateHasMissingData(true);
                    break;
                }
            case TO_REVIEW_FILTER_OPTIONS.NO_MATCHED_TRANSACTIONS:
                {
                    this.args.toggleNoMatchedTransactions(true);
                    break;
                }
        }
    }
    resetToReviewFilter() {
        this.args.toggleNoMatchedTransactions(false);
        this.args.updateDocumentType(null);
        if (this.args.tab === TABS.ALL_INVOICES) {
            this.args.updateHasMissingData(null);
            this.args.updateDueDate(null);
        }
    }
    @action
    updatePaymentDate(selected) {
        this.args.updatePaymentDate(selected?.code ?? null);
    }
    @action
    selectStatus(selected) {
        this.args.updateStatus(selected?.code);
    }
    @action
    selectMultiStatus(selected) {
        this.args.updateStatus(selected?.map((s)=>s.value).join(',') || undefined);
    }
    static{
        template_2440aa89e0574e65a335bda550fe47e5(`
    <div class={{styles.filters-wrapper}}>
      {{#each this.filters as |filter|}}
        <span data-test-filter={{filter}}>
          {{#if (and (eq filter FILTERS.SUPPLIERS) this.showSuppliersFilter)}}
            <SuppliersFilter
              @selected={{@selectedSupplierName}}
              @options={{@suppliers}}
              @onChange={{@selectSupplier}}
              @search={{@handleSupplierSearch}}
            />
          {{/if}}
          {{#if (eq filter FILTERS.ARCHIVED_PAID_STATUS)}}
            <FilterSelect
              @placeholder={{t 'supplier-invoices.completed.filter.label'}}
              @value={{this.selectedStatus}}
              @options={{this.paidArchiveStatusOptions}}
              @update={{this.selectStatus}}
              data-test-supplier-invoice-status-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          {{/if}}
          {{#if (eq filter FILTERS.PAYMENT_DATE)}}
            <FilterSelect
              @placeholder={{t 'supplier-invoices.filters.payment-date.placeholder'}}
              @value={{this.selectedPaymentDate}}
              @options={{this.paymentDateFilterOptions}}
              @update={{this.updatePaymentDate}}
              data-test-supplier-invoice-payment-date-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          {{/if}}
          {{#if (eq filter FILTERS.MULTI_STATUS)}}
            <ReactBridgeWithProviders
              @reactComponent={{MultiSelectFilter}}
              @props={{hash
                selected=this.selectedStatuses
                options=this.multiStatusFilterOptions
                placeholder=this.multiStatusFilterPlaceholder
                onSubmit=this.selectMultiStatus
              }}
            />
          {{/if}}
          {{#if (eq filter FILTERS.NO_MATCHED_TRANSACTIONS)}}
            <ReactBridge
              @reactComponent={{ToggleFilter}}
              @props={{hash
                isSelected=@noMatchedTransactionsActive
                onClick=@toggleNoMatchedTransactions
              }}
            >
              {{t 'supplier-invoices.filters.matched-transactions.placeholder'}}
            </ReactBridge>
          {{/if}}
          {{#if (eq filter FILTERS.TO_REVIEW)}}
            <FilterSelect
              @placeholder={{t 'supplier-invoices.filters.to-review.placeholder'}}
              @value={{this.toReviewSelected}}
              @options={{this.toReviewFilterOptions}}
              @update={{this.selectToReviewOption}}
              data-test-supplier-invoice-to-review-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          {{/if}}
          {{#if (eq filter FILTERS.DUE_DATE)}}
            <FilterSelect
              @placeholder={{t 'supplier-invoices.imported.filter.label'}}
              @value={{this.dueAndOverdueSelected}}
              @options={{this.dueAndOverdueOptions}}
              @update={{this.selectDueAndOverdue}}
              data-test-supplier-invoice-due-date-filter
              as |option|
            >
              {{option.value}}
            </FilterSelect>
          {{/if}}

        </span>
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
