import { template as template_3bd7512b889d49c59a97b9cb66ef4eba } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import XDropdownButton from '@qonto/ui-kit/components/x-dropdown-button';
import styles from './collapsible-timeline.strict-module.css';
import Timeline from './timeline';
import Description from './timeline/content/description';
export default class CollapsibleTimeline extends Component {
    @tracked
    isTimelineOpen;
    @action
    trackTimelineOpened() {
        this.isTimelineOpen = true;
        this.args.trackTimelineOpened?.();
    }
    static{
        template_3bd7512b889d49c59a97b9cb66ef4eba(`
    <XDropdownButton
      @triggerClass='btn'
      class={{styles.dropdown}}
      @renderInPlace={{true}}
      @onOpen={{this.trackTimelineOpened}}
      @onClose={{fn (mut this.isTimelineOpen) false}}
      ...attributes
      as |ddb|
    >
      <ddb.trigger
        @hasArrowIcon='true'
        class='{{styles.trigger}} {{if this.isTimelineOpen styles.open}}'
        data-test-trigger
      >
        {{@currentStatusText}}
      </ddb.trigger>
      <ddb.content class={{styles.timeline}} data-test-timeline>
        <Timeline>
          <:steps as |Step|>
            {{#each @steps as |event index|}}
              <Step
                class='{{event.icon.type}}{{if event.icon.filled "-filled-icon"}}'
                @iconType={{event.icon.type}}
                @lineStyle={{if event.lineStyle event.lineStyle 'solid'}}
                @shouldHideProgressLine={{event.isLastEvent}}
                data-test-step={{index}}
              >
                <Description
                  @description={{event.description}}
                  @caption={{event.caption}}
                  @additionalInformation={{event.additionalInformation}}
                  @tooltipInfoMessage={{event.tooltipInfoMessage}}
                />
              </Step>
            {{/each}}
          </:steps>
        </Timeline>
      </ddb.content>
    </XDropdownButton>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
