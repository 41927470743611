export default {
  "header": "qZq",
  "header-type": "qZZ",
  "close": "qZR",
  "close-icon": "qZQ",
  "header-infos": "qZe",
  "title": "qZB body-1",
  "declined": "qZE",
  "canceled": "qZd",
  "approved": "qZI",
  "pending": "qZt",
  "date": "qZM body-2",
  "container": "qZP",
  "picto": "qZl",
  "picto-status": "qZX",
  "general": "qZC",
  "amount": "qZk",
  "counterparty": "qZJ body-2",
  "initiator": "qZs",
  "initiator-avatar": "qZF",
  "avatar-image": "qZg",
  "initiator-id": "qZD",
  "name": "qZV body-2",
  "role": "qZL caption-bold",
  "infobox": "qZT",
  "account-infobox": "qZz qZT",
  "beneficiary-warning": "qZa caption-bold",
  "dates-wrapper": "qZH",
  "dates": "qZO",
  "recurrence": "qZm caption-bold",
  "arrow": "qZp",
  "notify-checkbox": "qZx"
};
