export default {
  "container": "cYd",
  "text-content": "cYI",
  "text-wrapper": "cYt",
  "actions-container": "cYM",
  "illustration": "cYP",
  "feature-tour-link": "cYl",
  "link-illustration": "cYX",
  "lottie": "cYC"
};
