/* import __COLOCATED_TEMPLATE__ from './direct-debit-collection.hbs'; */
import Component from '@glimmer/component';

import { ObfuscatedIban } from 'qonto/react/components/obfuscated-iban';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';

export default class PowerSelectMandatesMandateSelectTriggerComponent extends Component {
  Counterparty = Counterparty;
  ObfuscatedIban = ObfuscatedIban;
}
