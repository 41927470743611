export default {
  "header-cell": "cNi",
  "checkbox-cell": "cNA",
  "title-col": "cNY",
  "internal-note-col": "cNh",
  "unit-col": "cNf",
  "price-col": "cNK",
  "header-content": "cNG caption-bold",
  "active": "cNr",
  "empty": "cNb",
  "align-checkbox": "cNy"
};
