export default {
  "cell": "qJJ",
  "cell-label-container": "qJs",
  "cell-total-amount": "qJF",
  "cell-label": "qJg",
  "overflow": "qJD",
  "sub-label": "qJV body-2",
  "overdue": "qJL",
  "file-name-warning": "qJT",
  "missing": "qJz",
  "icon": "qJa",
  "flex-label": "qJH",
  "with-separator": "qJO",
  "self-invoice-icon": "qJm",
  "tooltip-wrapper": "qJp"
};
