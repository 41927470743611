export default {
  "row": "qgP",
  "active": "qgl",
  "dot": "qgX",
  "red": "qgC",
  "green": "qgk",
  "yellow": "qgJ",
  "align-right": "qgs",
  "animated": "qgF",
  "fadein-item": "qgg",
  "cell": "qgD body-2",
  "cell-content": "qgV",
  "amount": "qgL body-1",
  "subtitle": "qgT caption",
  "status": "qgz",
  "cell-amount": "qga"
};
