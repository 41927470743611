export default {
  "invoice-sidebar": "qMP",
  "body": "qMl",
  "box": "qMX",
  "box-header": "qMC",
  "sidebar-box": "qMk",
  "border-top": "qMJ",
  "row": "qMs",
  "greyed-out": "qMF",
  "strike-through": "qMg"
};
