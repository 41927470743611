export default {
  "content": "qSo",
  "balance": "qSn",
  "duration": "qSi",
  "green-text": "qSA",
  "balance-subtitle": "qSY",
  "balance-duration": "qSh",
  "footer": "qSf",
  "progress": "qSK",
  "progress-bar": "qSG",
  "progress-bar-text": "qSr",
  "progress-active": "qSb"
};
