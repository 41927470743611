export default {
  "row": "qsD",
  "greyed-out": "qsV",
  "supplier-name": "qsL",
  "cell": "qsT",
  "supplier": "qsz",
  "email-address": "qsa",
  "outstanding-balance": "qsH",
  "quick-actions": "qsO",
  "empty": "qsm",
  "supplier-container": "qsp",
  "supplier-avatar": "qsx",
  "grey-background": "qsw"
};
