/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class Input extends Component {
  @action
  onInput(event) {
    this.args.handleInput(event.target.value);
  }

  @action
  onFocus() {
    this.args.select.actions.open();
    if (this.args.value) {
      this.args.handleInput(this.args.value);
    }
  }

  @action
  onKeyPress() {
    this.args.select.actions.open();
  }
}
