export default {
  "display-block": "qwL",
  "pay-later-tooltip": "qwT",
  "disabled": "qwz",
  "stepper": "qwa",
  "installments": "qwH",
  "flex-items": "qwO",
  "day-label": "qwm body-2",
  "installment-amount": "qwp body-1",
  "spacing": "qwx",
  "info-icon": "qww",
  "detail": "qwN",
  "detail-value": "qwv",
  "extra": "qwo",
  "extra-value": "qwn",
  "badge": "qwi",
  "title": "qwA",
  "link": "qwY"
};
