export default {
  "header-cell": "qxc",
  "empty": "qxq",
  "header-content": "qxZ caption-bold",
  "active": "qxR",
  "header-type": "qxQ",
  "header-reason": "qxe",
  "header-status": "qxB",
  "header-amount": "qxE",
  "row-sidebar": "qxd",
  "hidden": "qxI",
  "animated": "qxt",
  "fadein-item": "qxM",
  "animated-cell": "qxP"
};
