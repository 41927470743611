export default {
  "container": "cwi",
  "cards-container": "cwA",
  "card": "cwY",
  "expanded": "cwh",
  "hidden": "cwf",
  "collapsed": "cwK",
  "with-expand-collapse-animation": "cwG",
  "with-dismiss-animation": "cwr",
  "has-one-card": "cwb",
  "dismissing": "cwy",
  "with-hover-animation": "cwU",
  "toggle-button": "cwj",
  "toggle-container": "cwW",
  "toggle-button-icon": "cwu",
  "has-two-cards": "cNS"
};
