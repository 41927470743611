/* import __COLOCATED_TEMPLATE__ from './confirmation.hbs'; */
import { assert } from '@ember/debug';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { allSettled, dropTask, task } from 'ember-concurrency';

import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
import { ErrorInfo } from 'qonto/utils/error-info';

export const MISSING_DATA_CONTEXT_ERROR_MESSAGE = 'A data context object must be provided';

export default class TransfersInternationalOutProvidersConfirmation extends Component {
  @service abilities;
  @service internationalOutManager;
  @service intl;
  @service organizationManager;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    assert(MISSING_DATA_CONTEXT_ERROR_MESSAGE, this.dataContext);
  }

  get dataContext() {
    return this.args.context;
  }

  confirmTask = dropTask(async () => {
    let [{ value: budgetName }, { value: confirmationResult }] = await allSettled([
      this.getBudgetNameTask.perform(),
      this.getConfirmationResultTask.perform(),
    ]);

    if (budgetName) {
      this.dataContext.budgetName = budgetName;
    }

    if (confirmationResult) {
      this.dataContext.confirmationResult = confirmationResult;
    }

    return Boolean(confirmationResult);
  });

  getBudgetNameTask = task(async () => {
    if (this.abilities.cannot('read budget')) {
      return null;
    }

    try {
      let [searchResult] = await this.store.adapterFor('budget').search({
        initiatorId: this.organizationManager.membership.id,
        scheduledDate: this.dataContext.quote.estimatedDelivery,
      });

      return searchResult?.budget.name || null;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return null;
    }
  });

  getConfirmationResultTask = task(async () => {
    let { bankAccount, beneficiary, fees, quote } = this.dataContext;

    try {
      return await this.internationalOutManager.confirmTransfer({
        amount: {
          value: formatAmountToNumber({
            amount: quote.sourceAmount + fees.total,
            precision: getAllowedDecimalPlaces(quote.sourceCurrency),
          }),
          currency: quote.sourceCurrency,
        },
        bankAccountId: bankAccount.id,
        beneficiaryId: beneficiary.id,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      return null;
    }
  });
}
