/* import __COLOCATED_TEMPLATE__ from './details-sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { INVOICE_STATUSES, SOURCE_TYPES } from 'qonto/constants/supplier-invoice';

export default class DetailsSidebarComponent extends Component {
  @service abilities;
  @service toastFlashMessages;
  @service router;
  @service segment;
  @service organizationManager;
  @service intl;

  @reads('organizationManager.organization') organization;

  @tracked showEditForm =
    this.router.currentRoute?.queryParams.edit === 'true' || this.showEinvoicingForm;

  @action
  toggleShowEditForm() {
    this.showEditForm = !this.showEditForm;
    this.router.transitionTo({ queryParams: { edit: this.showEditForm } });
  }

  get showEinvoicingForm() {
    return (
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      this.args.invoice?.status === INVOICE_STATUSES.paid
    );
  }

  get showCTA() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay, INVOICE_STATUSES.pending].includes(
        this.args.invoice?.status
      )
    );
  }

  get showSection() {
    return this.showCTA && !this.showEditForm;
  }

  get showRequestValidation() {
    return this.args.request && this.args.invoice.status === INVOICE_STATUSES.pending;
  }

  get showSelfInvoiceSection() {
    let isEInvoice = this.args.invoice?.source === SOURCE_TYPES.E_INVOICING;
    return this.abilities.can('read self-invoice') && !isEInvoice;
  }

  get beneficiaryWarning() {
    return this.args.confirmTransferResult?.beneficiaryWarning;
  }

  get confirmErrors() {
    return this.args.confirmTransferResult?.confirmErrors;
  }

  get confirmWarnings() {
    return this.args.confirmTransferResult?.confirmWarnings;
  }

  get spendLimitsWarning() {
    return this.args.confirmTransferResult?.spendLimitsWarning;
  }

  get spendLimits() {
    return this.args.confirmTransferResult?.spendLimits;
  }

  onApproveRequestTask = dropTask(async request => {
    await this.args.approveRequestTask.perform(request, this.args.invoice);
  });

  onDeclineRequestTask = dropTask(async request => {
    await this.args.declineRequestTask.perform(request, this.args.invoice);
  });

  @action
  onCancel() {
    this.args.onRequestWorkflow?.();
    this.segment.track('supplier-invoices_request-cancel_clicked');

    this.toastFlashMessages.clearMessages();
    this.toastFlashMessages.toastInfo(
      this.intl.t('supplier-invoices.info-toast.transfers.request-cancelled')
    );
  }
}
