export default {
  "transaction": "qLh",
  "transaction-details": "qLf",
  "transaction-details-amount": "qLK body-1",
  "credit": "qLG",
  "negative-amount": "qLr",
  "transaction-details-description": "qLb body-2",
  "transaction-remove-cta": "qLy",
  "avatar": "qLU mr-16",
  "spinner": "qLj"
};
