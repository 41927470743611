export default {
  "summary-wrapper": "crU",
  "summary-title": "crj",
  "summary-list": "crW",
  "summary-list-item-wrapper": "cru",
  "summary-list-item-record": "cbS",
  "summary-list-item-avatar": "cbc",
  "summary-list-item-avatar-image": "cbq",
  "summary-list-item-name-count": "cbZ"
};
