export default {
  "invoice-sidebar": "chC",
  "body": "chk",
  "box": "chJ",
  "box-header": "chs",
  "footer": "chF",
  "footer-button": "chg",
  "sidebar-box": "chD",
  "border-top": "chV",
  "row": "chL",
  "status-container": "chT",
  "due-date-container": "chz",
  "mark-as-btn": "cha",
  "primary-actions": "chH chD",
  "primary-action": "chO body-1",
  "button-icon": "chm"
};
