export default {
  "header-cell": "qFr",
  "header-content": "qFb caption-bold",
  "active": "qFy",
  "empty": "qFU",
  "align-right": "qFj",
  "type": "qFW",
  "requester": "qFu",
  "request-date": "qgS",
  "amount": "qgc",
  "status": "qgq"
};
