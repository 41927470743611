/* import __COLOCATED_TEMPLATE__ from './bookkeeping.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { VatDetails } from 'qonto/react/components/transactions/table/side-panel/details/vat';
import transactionHasVat from 'qonto/utils/transaction-has-vat';

export default class BookkeepingComponent extends Component {
  badgeStatus = BadgeStatus;

  @reads('args.transaction.declined') transactionDeclined;
  @reads('args.transaction.isBilling') transactionIsBilling;
  @reads('args.transaction.isDeclinedTransfer') transactionIsDeclinedTransfer;

  @service abilities;
  @service segment;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  VatDetails = VatDetails;

  constructor() {
    super(...arguments);

    assert('@transaction is required', this.args.transaction);
    assert('@openDetails is required', this.args.openDetails);
    assert('@markAsVerified is required', this.args.markAsVerified);
  }

  get level() {
    return this.args.transaction.qualifiedForAccounting ? 'validated' : 'error';
  }

  get text() {
    return this.args.transaction.qualifiedForAccounting
      ? this.intl.t('transactions.sidebar.bookkeeping.badge.verified')
      : this.intl.t('transactions.sidebar.bookkeeping.badge.to-be-verified');
  }

  get subjectDeclined() {
    return this.args.transaction.subject?.get('declined');
  }

  get isNotDeclined() {
    return !this.subjectDeclined && !this.transactionDeclined;
  }

  get canManageStatus() {
    return this.isNotDeclined && this.abilities.can('qualify for accounting transactions');
  }

  get canShowVat() {
    return this.isNotDeclined && transactionHasVat(this.args.transaction);
  }

  markAsVerifiedTask = dropTask(async () => {
    let isVerified = !this.args.transaction.qualifiedForAccounting;
    let transactionIds = [this.args.transaction.id];

    try {
      await this.args.markAsVerified(isVerified, transactionIds);

      this.track(isVerified);

      let successMessage = isVerified
        ? this.intl.t('attachment-viewer.verified.footer.qualified.toast.success')
        : this.intl.t('attachment-viewer.verified.footer.disqualified.toast.success');

      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      this.sentry.captureException(
        new Error(`Transaction sidebar mark as verified triggered an error`, { cause: error })
      );

      this.toastFlashMessages.toastError(
        this.intl.t('attachment-viewer.bookkeeping.generic-error.toast')
      );
    }
  });

  track(isVerified) {
    let segmentTracker = isVerified
      ? 'history_transaction_side_panel_mark_as_verified_checkbox_ticked'
      : 'history_transaction_side_panel_mark_as_verified_checkbox_unticked';

    this.segment.track(segmentTracker);
  }

  @action
  handleEdit() {
    this.segment.track('transaction_analytic_label_manage_label_clicked');
    this.args.openDetails();
  }
}
