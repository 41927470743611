/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeStatus } from '@repo/design-system-kit';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

const GERMAN_E_INVOICES_ARTICLE_FOR_DE = 'https://qonto.com/de/invoicing/e-invoicing';
const GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS = 'https://qonto.com/en/invoicing/e-invoicing';

export default class SupplierInvoicesTableCellComponent extends Component {
  InstructionalTooltip = InstructionalTooltip;
  Badge = BadgeStatus;

  @service intl;
  @service zendeskLocalization;
  @service organizationManager;
  @service localeManager;

  @tracked isFirstFrenchEinvoiceTooltipDismissed = false;
  @tracked hasDismissedTooltipForFirstGermanEInvoice = false;

  get shouldDisplayFirstEinvoiceTooltip() {
    let { firstEInvoiceId, cell, key } = this.args;
    return firstEInvoiceId === cell?.id && key === 'supplierName';
  }

  get showTooltipOrEInvoiceLabel() {
    let { isEInvoice, hasSelfInvoice } = this.args.cell;
    return isEInvoice || hasSelfInvoice;
  }

  get isStatus() {
    return Object.values(INVOICE_STATUSES).includes(this.args.cell);
  }

  get einvoicingFAQLink() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return this.intl.t('supplier-invoices.instructional-tooltip.einvoicing-disclaimer.url', {
          faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
        });
      case 'DE':
        if (this.currentLocale === 'de') {
          return this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.url',
            {
              lpUrl: GERMAN_E_INVOICES_ARTICLE_FOR_DE,
            }
          );
        } else {
          return this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.url',
            {
              lpUrl: GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS,
            }
          );
        }
      default:
        break;
    }
  }

  get toolTipTextContent() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return {
          title: this.intl.t('supplier-invoices.first-einvoice-card.title'),
          description: this.intl.t('supplier-invoices.first-einvoice-card.subtitle.body'),
          linkText: this.intl.t(
            'supplier-invoices.instructional-tooltip.einvoicing-disclaimer.link'
          ),
          faqSource: '',
        };
      case 'DE':
        return {
          title: this.intl.t('supplier-invoices.table.instructional-tooltip.first-einvoice.title'),
          description: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.body'
          ),
          linkText: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.link'
          ),
          faqSource: 'first-einvoice',
        };
      default:
        break;
    }
  }

  get organizationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get currentLocale() {
    return this.localeManager.locale;
  }

  get isInstructionalToolTipDismissed() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return this.isFirstFrenchEinvoiceTooltipDismissed;

      case 'DE':
        return this.hasDismissedTooltipForFirstGermanEInvoice;

      default:
        return false;
    }
  }

  @action
  handleFirstFrenchEinvoiceTooltipClose() {
    this.isFirstFrenchEinvoiceTooltipDismissed = true;
    safeLocalStorage.setItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY, true);
  }

  @action
  handleCloseFirstGermanEInvoiceTooltip() {
    this.hasDismissedTooltipForFirstGermanEInvoice = true;
    safeLocalStorage.setItem(FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY, true);
  }

  @action
  handleInstructionalTooltipClosure() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        this.handleFirstFrenchEinvoiceTooltipClose();
        break;

      case 'DE':
        this.handleCloseFirstGermanEInvoiceTooltip();
        break;

      default:
        break;
    }
  }
}
