export default {
  "fileupload": "qvt",
  "file": "qvM",
  "label": "qvP",
  "zone": "qvl",
  "error": "qvX",
  "hide": "qvC",
  "dropzone": "qvk",
  "hidden": "qvJ",
  "upload-icon": "qvs",
  "dropping": "qvF",
  "bounce2": "qvg",
  "error-message": "qvD"
};
