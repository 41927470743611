/* import __COLOCATED_TEMPLATE__ from './check.hbs'; */
import Component from '@glimmer/component';

import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';

export default class TransactionsSidebarHeaderExternalCheck extends Component {
  Counterparty = Counterparty;
  Date = Date;
  type = Type;
}
