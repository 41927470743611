export default {
  "card": "cwF",
  "card-primary": "cwg",
  "content": "cwD",
  "content-primary": "cwV",
  "disabled": "cwL",
  "illustration": "cwT",
  "illustration-primary": "cwz",
  "illustration-disabled": "cwa",
  "cta": "cwH",
  "dismiss-button": "cwO",
  "buttons": "cwm",
  "required-badge": "cwp"
};
