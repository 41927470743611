export default {
  "card-sidebar-header": "qql",
  "content": "qqX",
  "header-type": "qqC",
  "close": "qqk",
  "close-icon": "qqJ",
  "header-infos": "qqs",
  "title": "qqF body-1",
  "danger": "qqg",
  "success": "qqD",
  "warning": "qqV",
  "date": "qqL body-2",
  "card-image-container": "qqT",
  "card-image": "qqz",
  "info-container": "qqa",
  "spending-reason": "qqH",
  "spending-reason-label": "qqO body-2 mr-8",
  "spending-reason-note": "qqm body-2"
};
