/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox, DatePicker, TextAreaField } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { reads } from 'macro-decorators';

export default class TransferMultiInformation extends Component {
  checkbox = Checkbox;
  datePicker = DatePicker;
  textAreaField = TextAreaField;

  @service organizationManager;
  @service intl;

  @reads('organizationManager.organization') organization;
  @reads('organization.hasMultipleActiveCurrentRemuneratedAccounts') showAccountSelect;
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts') activeAccounts;

  @tracked validationState = 'valid';
  @tracked errorMessage = null;

  get scheduledDate() {
    let { multiTransfer } = this.args;
    return multiTransfer.scheduledDate ? parseDate(multiTransfer.scheduledDate) : null;
  }

  get minValue() {
    let { multiTransfer } = this.args;
    return multiTransfer.minDate ? parseDate(multiTransfer.minDate) : null;
  }

  @action
  onChange(date) {
    this.args.updateScheduleSelection(date);

    if (!date) {
      this.validationState = 'invalid';
      this.errorMessage = this.intl.t('validations.errors.presence');
      return;
    }

    if (dayjs(date.toString()).isBefore(this.minValue, 'day')) {
      this.validationState = 'invalid';
      this.errorMessage = this.intl.t('transfers.multi.datepicker.error.minimum-date', {
        date: dateToken({
          date: new Date(),
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.COMPACT,
        }),
      });
      return;
    }
    this.validationState = 'valid';
    this.errorMessage = null;
  }
}
