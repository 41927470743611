export default {
  "header-cell": "qpq",
  "empty": "qpZ",
  "header-type": "qpR",
  "header-requester": "qpQ",
  "header-amount": "qpe",
  "header-approval": "qpB",
  "header-content": "qpE caption-bold",
  "active": "qpd",
  "row-compact": "qpI",
  "row-sidebar": "qpt",
  "hidden": "qpM",
  "animated": "qpP",
  "fadein-item": "qpl",
  "animated-cell": "qpX"
};
