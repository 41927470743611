export default {
  "row": "qcA",
  "empty": "qcY",
  "active": "qch",
  "animated": "qcf",
  "fadein-item": "qcK",
  "cell": "qcG body-2",
  "cell-content": "qcr",
  "cell-amount": "qcb",
  "cell-content-amount": "qcy body-1"
};
