export default {
  "row": "cKW",
  "cell": "cKu",
  "greyed-out": "cGS",
  "struck-through": "cGc",
  "flex-cell": "cGq",
  "overdue": "cGZ",
  "align-right": "cGR",
  "customer-name-container": "cGQ",
  "icon": "cGe",
  "warning": "cGB",
  "with-separator": "cGE",
  "align-center": "cGd"
};
