/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  LOCAL_STORAGE_WARNING_DISMISSED_KEY,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ReceivableInvoicesNewGeneralComponent extends Component {
  @service intl;
  @service router;
  @service modals;
  @service sentry;
  @service toastFlashMessages;
  @service segment;
  @service organizationManager;

  get isQuote() {
    return this.args.isQuote;
  }

  get primeTaskButtonLabel() {
    if (this.isQuote) {
      return this.intl.t('receivable-invoices.quote-creation.create.cta');
    }
    return this.intl.t('receivable-invoices.invoice-creation.create.cta');
  }

  triggerAfterCreateModalTask = dropTask(async creationEvent => {
    if (creationEvent) {
      this.segment.track(creationEvent);
    }
    if (this.isQuote) {
      if (this.router.currentRouteName.includes('quotes.edit')) {
        await this.updateQuoteTask.perform();
        return;
      }
      await this.saveQuoteTask.perform();
      return;
    }
    await this.triggerInvoiceCreationTask.perform();
  });

  get shouldDisplayCheckbox() {
    return (
      this.args.settings?.numberingMode === 'manual' &&
      safeLocalStorage.getItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY) !== 'true'
    );
  }

  get invoiceCreationDescription() {
    let shouldDisplayItalyCopy = this.organizationManager.organization.legalCountry === 'IT';

    if (this.args.invoice?.status === 'draft') {
      return shouldDisplayItalyCopy
        ? this.intl.t('receivable-invoices.issue-modal.edit-draft.italy.subtitle')
        : this.intl.t('receivable-invoices.issue-modal.edit-draft.subtitle');
    }

    // FR e-invoicing condition
    if (this.shouldDisplayEinvoicing) {
      return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
    }

    return shouldDisplayItalyCopy
      ? this.intl.t('receivable-invoices.issue-modal.italy.subtitle')
      : this.intl.t('receivable-invoices.issue-modal.subtitle');
  }

  get shouldDisplayEinvoicing() {
    return this.args.invoice?.isEinvoice;
  }

  triggerInvoiceCreationTask = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('receivable-invoices.issue-modal.title'),
        description: this.invoiceCreationDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.issue-modal.cta-issue'),
        confirmTask: this.confirmTask,
        shouldDisplayCheckbox: this.shouldDisplayCheckbox,
        shouldDisplayEinvoicing: this.shouldDisplayEinvoicing,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  confirmTask = dropTask(async closeModal => {
    if (this.args.primaryTask) {
      await this.args.primaryTask.perform(closeModal);
    } else {
      await this.saveTask.perform(closeModal);
    }
    if (this.shouldDisplayCheckbox) {
      safeLocalStorage.setItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY, 'true');
    }
  });

  saveTask = dropTask(async closeModal => {
    try {
      if (this.args.settings?.numberingMode === 'automatic') {
        this.args.invoice.number = null;
      }
      await this.args.invoice.save();
      this.segment.track(this.args.confirmationEvent);
      // Remove locally stored items because backend return copies of those items with new ids
      this.args.invoice.clearItemsWithNoId();

      let origin = this.router.currentRouteName;

      this.router.transitionTo('receivable-invoices.share', this.args.invoice.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  saveQuoteTask = dropTask(async () => {
    try {
      await this.args.invoice.save();
      this.segment.track('quote_creation_confirmed');
      this.args.invoice.clearItemsWithNoId();
      this.router.transitionTo('quotes.share', this.args.invoice.id);
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.create-quote-modal.success')
      );
    } catch (error) {
      this.handleError(error);
    }
  });

  updateQuoteTask = dropTask(async () => {
    try {
      await this.args.invoice.updateQuote();
      this.args.invoice.clearItemsWithNoId();
      this.router.transitionTo('quotes.share', this.args.invoice.id);
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.create-quote-modal.success')
      );
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
  }

  scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }
}
