export default {
  "row": "qNm",
  "counterparty-name": "qNp",
  "counterparty-status": "qNx",
  "amount": "qNw",
  "next-date": "qNN",
  "end-date": "qNv",
  "frequency": "qNo",
  "active": "qNn",
  "cell": "qNi body-2",
  "no-padding": "qNA",
  "cell-content": "qNY",
  "counterparty": "qNh",
  "status-avatar": "qNf",
  "beneficiary-name": "qNK",
  "empty": "qNG",
  "disabled": "qNr",
  "fadein-item": "qNb"
};
