export default {
  "header-cell": "qgZ",
  "header-content": "qgR caption-bold",
  "active": "qgQ",
  "empty": "qge",
  "type": "qgB",
  "requester": "qgE",
  "request-date": "qgd",
  "amount": "qgI",
  "receipt": "qgt",
  "quick-actions": "qgM"
};
