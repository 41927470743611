export default {
  "container": "cAJ",
  "title-and-trash": "cAs",
  "trash": "cAF",
  "quantity-amount": "cAg",
  "flex-row": "cAD",
  "quantity": "cAV",
  "quantity-input": "cAL",
  "border-left": "cAT",
  "dropdown": "cAz",
  "item-row": "cAa",
  "unit-price": "cAH",
  "price-input": "cAO",
  "hidden": "cAm",
  "d-none": "cAp",
  "dropdown-option": "cAx",
  "list-row": "cAw",
  "row-title": "cAN",
  "row-price": "cAv body-2",
  "row-missing-details-container": "cAo",
  "row-missing-details-label": "cAn body-2",
  "warning-icon": "cAi",
  "row-internal-note": "cAA",
  "with-internal-note": "cAY",
  "dropdown-content": "cAh",
  "select-item": "cAf"
};
