export default {
  "gap-32": "ciI",
  "fieldset": "cit",
  "date-picker": "ciM",
  "with-performance-date": "ciP",
  "mt-4": "cil",
  "clients": "ciX",
  "dropdown-option": "ciC",
  "dropdown-content": "cik",
  "select-client": "ciJ",
  "client-option": "cis",
  "icon-tooltip": "ciF",
  "payment-details": "cig",
  "dates-container": "ciD",
  "automatic-number-label": "ciV",
  "label": "ciL",
  "numbering-container": "ciT",
  "fix-width": "ciz",
  "customer-error": "cia",
  "amount-text": "ciH",
  "header-text-field-container": "ciO",
  "header-text-field": "cim",
  "header-text-field-close-button": "cip",
  "mt-16": "cix"
};
