export default {
  "transfer-header": "qak",
  "infobox": "qaJ header-section",
  "proof-section": "qas",
  "proof-link": "qaF",
  "download-icon": "qag",
  "cancel-button": "qaD",
  "request-refund-button": "qaV",
  "refund-requested-indicator": "qaL",
  "recall-indicator": "qaT",
  "payment-purpose": "qaz",
  "payment-purpose-label": "qaa",
  "repeat-infobox": "qaH qaJ header-section",
  "additional-infobox": "qaO qaJ header-section",
  "repeat-transfer-action": "qam",
  "financing-not-compliant-disclaimer": "qap"
};
