export default {
  "row": "qpT body-1",
  "active": "qpz",
  "cell": "qpa",
  "empty": "qpH",
  "ellipsis": "qpO",
  "text-secondary": "qpm",
  "transfer-type": "qpp",
  "transfer-type__icon": "qpx",
  "quick-actions": "qpw",
  "amount": "qpN",
  "cell-approve": "qpv",
  "cell-type": "qpo",
  "cell-requester": "qpn",
  "account-select": "qpi",
  "row-compact": "qpA",
  "row-sidebar": "qpY",
  "cell-amount": "qph",
  "hidden": "qpf",
  "animated": "qpK",
  "fadein-item": "qpG",
  "animated-cell": "qpr"
};
