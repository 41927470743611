export default {
  "wrapper": "qDb",
  "section-wrapper": "qDy",
  "header": "qDU",
  "title": "qDj body-1",
  "close-icon": "qDW",
  "section": "qDu",
  "section-title": "qVS caption-bold",
  "labels": "qVc",
  "labels-title": "qVq",
  "section-checkbox": "qVZ",
  "grow": "qVR scroll",
  "label": "qVQ",
  "buttons": "qVe"
};
