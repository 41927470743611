export default {
  "card": "czj",
  "header": "czW",
  "avatar": "czu",
  "title": "caS",
  "subtitle": "cac",
  "label": "caq",
  "item": "caZ body-2",
  "header-icon": "caR",
  "mt-8": "caQ"
};
