export default {
  "header-cell": "qNs",
  "align-right": "qNF",
  "header-content": "qNg caption-bold",
  "active": "qND",
  "empty": "qNV",
  "col-12": "qNL",
  "col-8": "qNT",
  "col-6": "qNz",
  "col-4": "qNa",
  "col-2": "qNH",
  "hide": "qNO"
};
