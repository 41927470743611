export default {
  "notifications-settings": "cTS",
  "opened": "cTc",
  "icon": "cTq",
  "header": "cTZ",
  "header-toggle": "cTR",
  "avatar-image": "cTQ",
  "title": "cTe",
  "icon--right": "cTB",
  "toggle-container": "cTE",
  "toggle": "cTd"
};
