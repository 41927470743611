export default {
  "header": "cyQ",
  "header-type": "cye",
  "close": "cyB",
  "close-icon": "cyE",
  "header-infos": "cyd",
  "title": "cyI body-1",
  "declined": "cyt",
  "canceled": "cyM",
  "approved": "cyP",
  "pending": "cyl",
  "date": "cyX body-2",
  "mileage-icon": "cyC",
  "distance-subtitle": "cyk body-2",
  "link-icon": "cyJ",
  "container": "cys",
  "picto": "cyF",
  "general": "cyg",
  "amount": "cyD",
  "calculation-container": "cyV",
  "counterparty": "cyL body-2",
  "infobox": "cyT"
};
