/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

export default class CustomPeriodDatePicker extends Component {
  get startDate() {
    let { startDate } = this.args;

    return startDate ? dayjs(startDate).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  get endDate() {
    let { endDate } = this.args;

    return endDate ? dayjs(endDate).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  @action
  updateDateFrom(date) {
    this.args.onSelectStartDate(date ? dayjs(date).toDate() : null);
  }

  @action
  updateDateTo(date) {
    this.args.onSelectEndDate(date ? dayjs(date).toDate() : null);
  }

  get minStartDate() {
    return getMinStartDate();
  }

  get maxStartDate() {
    return getMaxStartDate();
  }

  get minEndDate() {
    return getMinEndDate(this.startDate);
  }

  get maxEndDate() {
    return getMaxEndDate();
  }
}

export function validatePeriod(startDate, endDate) {
  if (!startDate || !endDate) {
    return false;
  }

  if (dayjs(startDate).isBefore(getMinStartDate())) {
    return false;
  }
  if (dayjs(startDate).isAfter(getMaxStartDate())) {
    return false;
  }

  if (dayjs(endDate).isBefore(getMinEndDate(startDate))) {
    return false;
  }

  if (dayjs(endDate).isAfter(getMaxEndDate())) {
    return false;
  }

  return true;
}

function getMinStartDate() {
  return '2015-01-02';
}

function getMaxStartDate() {
  return dayjs().add(2, 'years').subtract(2, 'days').format(DATE_PICKER_FIELD_FORMAT);
}

function getMinEndDate(startDate) {
  return dayjs(startDate || getMinStartDate())
    .add(1, 'day')
    .format(DATE_PICKER_FIELD_FORMAT);
}

function getMaxEndDate() {
  return dayjs().add(2, 'years').subtract(1, 'day').format(DATE_PICKER_FIELD_FORMAT);
}
