export default {
  "payment-details": "cAK",
  "label-payment-conditions": "cAG",
  "dropdown-content": "cAr",
  "dropdown-option": "cAb",
  "activate-sdd-container": "cAy",
  "activate-sdd-header": "cAU",
  "selector-toggle-sdd": "cAj",
  "selector-toggle-sdd-subtitle": "cAW",
  "selector-toggle-content": "cAu",
  "selector-toggle": "cYS",
  "new-badge": "cYc",
  "new-badge-activation": "cYq",
  "mt-16": "cYZ",
  "sdd-mandate-select-loading": "cYR"
};
