export default {
  "section-container": "qZy",
  "disclaimer-section": "qZU",
  "trip-title": "qZj caption-bold mb-16",
  "google-link-container": "qZW",
  "link-icon": "qZu",
  "trip-details": "qRS body-2",
  "trip-details-icon": "qRc",
  "trip-details-title": "qRq",
  "subtitle": "qRZ caption-bold mb-16",
  "disclaimer": "qRR",
  "visualizer": "qRQ"
};
