export default {
  "card": "czv",
  "header": "czo",
  "avatar": "czn",
  "title": "czi",
  "subtitle": "czA",
  "label": "czY",
  "item": "czh body-2",
  "header-icon": "czf",
  "dropdown-menu": "czK",
  "menu-item": "czG body-2",
  "delete": "czr"
};
