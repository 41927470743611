export default {
  "header-cell": "qsM",
  "first-col": "qsP",
  "mid-col": "qsl",
  "outstanding-col": "qsX",
  "header-content": "qsC caption-bold",
  "active": "qsk",
  "quick-actions": "qsJ",
  "empty": "qss"
};
