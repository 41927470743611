export default {
  "custom-export-loading": "qLZ",
  "title": "qLR",
  "description": "qLQ",
  "section": "qLe",
  "placeholder-avatar": "qLB",
  "_with-avatar": "qLE",
  "_with-list": "qLd",
  "list-item": "qLI",
  "radio": "qLt",
  "header": "qLM",
  "header-line": "qLP"
};
