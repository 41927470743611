/* import __COLOCATED_TEMPLATE__ from './month.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

export default class OverviewChartTooltipMonthComponent extends Component {
  @service intl;

  get monthlyCashflow() {
    let monthlyCashflow = this.args.monthlyCashflow;
    if (dayjs(monthlyCashflow.time).isAfter(Date.now())) {
      return Object.keys(monthlyCashflow).forEach(key => {
        monthlyCashflow[key] = undefined;
      });
    } else {
      return monthlyCashflow;
    }
  }

  get monthlyTotalTranslation() {
    return this.args.isCurrent
      ? this.intl.t('overview.month-to-date')
      : this.intl.t('overview.monthly-total');
  }
}
