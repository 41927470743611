export default {
  "wrapper": "qSJ",
  "card-header": "qSs",
  "subtitle-caption": "qSF",
  "estimated-earnings": "qSg",
  "progress": "qSD",
  "progress-bar": "qSV",
  "progress-bar-text": "qSL",
  "subtitle": "qST",
  "badge": "qSz",
  "core-information": "qSa",
  "secondary-information": "qSH",
  "remuneration-table": "qSO",
  "name": "qSm title-3",
  "separator": "qSp",
  "remuneration-line": "qSx",
  "german-disclaimer": "qSw",
  "table": "qSN",
  "table-right": "qSv"
};
