/* import __COLOCATED_TEMPLATE__ from './timeout.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

export default class TransfersSepaSettlementTimeoutComponent extends Component {
  lottiePlayer = LottiePlayer;

  @tracked isAnimationReady = false;
}
