export default {
  "banner": "cae",
  "content": "caB",
  "title-row": "caE",
  "title": "cad title-3",
  "badge": "caI",
  "text": "cat body-2",
  "missing-info-disclaimer": "caM",
  "button": "caP btn btn--primary",
  "spinner": "cal",
  "illustration": "caX",
  "image": "caC"
};
