export default {
  "wrapper": "crs",
  "tabs": "crF",
  "tabs-nav": "crg",
  "tabs-panel-wrapper": "crD",
  "list-head": "crV",
  "list": "crL",
  "footer": "crT",
  "reward-amount": "crz",
  "earned": "cra",
  "pending": "crH",
  "empty-state-wrapper": "crO",
  "empty-state": "crm",
  "illustration": "crp",
  "error-wrapper": "crx",
  "error-illustration": "crw",
  "error-description": "crN",
  "spinner": "crv",
  "total-earned": "cro"
};
