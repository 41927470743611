export default {
  "row": "qpC",
  "cell": "qpk",
  "empty": "qpJ",
  "icon": "qps",
  "type": "qpF",
  "amount-content": "qpg",
  "cell-approve": "qpD",
  "approve-content": "qpV",
  "row-compact": "qpL"
};
