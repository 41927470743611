export default {
  "modal": "cfa",
  "action-container": "cfH",
  "similar-label": "cfO",
  "info-icon": "cfm",
  "container": "cfp",
  "subtitle": "cfx caption-bold",
  "error": "cfw",
  "item-container": "cfN"
};
