export default {
  "form-content": "cKl",
  "row": "cKX",
  "numbering-format": "cKC",
  "numbering-fields": "cKk",
  "input-settings": "cKJ",
  "next-invoice-number": "cKs",
  "spacing": "cKF",
  "label-tooltip": "cKg",
  "tooltip-icon": "cKD",
  "customize-field": "cKV"
};
