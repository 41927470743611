export default {
  "container": "cOH",
  "indicator-container": "cOO",
  "transactions-container": "cOm",
  "indicator": "cOp",
  "cashflow-container": "cOx",
  "cashflow": "cOw",
  "donut-container": "cON",
  "donut": "cOv"
};
