export default {
  "donut-chart-container": "caA",
  "donut-chart-wrapper": "caY",
  "chart": "cah",
  "donut-chart-legend-container": "caf caption",
  "donut-chart-legend-item-empty": "caK",
  "donut-chart-legend-bullet-empty": "caG",
  "donut-chart-legend-bullet": "car",
  "donut-chart-legend-text-empty": "cab",
  "donut-chart-legend-text": "cay",
  "donut-chart-custom-label-uncategorized": "caU",
  "custom-label-uncategorized-button": "caj body-1",
  "donut-chart-legend": "caW",
  "donut-chart-legend-item": "cau",
  "donut-chart-legend-other-item": "cHS",
  "donut-chart-legend-uncategorized-item": "cHc",
  "donut-chart-legend-text-name": "cHq",
  "tooltip": "cHZ"
};
