export default {
  "wrapper": "qOI",
  "ember-power-select-dropdown": "qOt",
  "status": "qOM body-1",
  "error": "qOP",
  "success": "qOl",
  "type-container": "qOX",
  "type-close": "qOC",
  "date": "qOk",
  "close-icon": "qOJ",
  "container": "qOs",
  "container-picto": "qOF",
  "avatar": "qOg",
  "container-picto-status": "qOD",
  "general": "qOV",
  "general-amount": "qOL",
  "unprocessed": "qOT",
  "fx-rate": "qOz",
  "general-counterparty": "qOa body-2",
  "infobox": "qOH",
  "account-infobox": "qOO qOH",
  "initiator-infobox": "qOm qOH",
  "transaction-infobox": "qOp qOH",
  "repeat-infobox": "qOx qOH",
  "item": "qOw body-2",
  "eye-icon": "qON",
  "repeat-transfer-action": "qOv",
  "disclaimer": "qOo",
  "start-verification-link": "qOn",
  "financing-not-compliant-disclaimer": "qOi"
};
