export default {
  "container": "cKL",
  "content": "cKT",
  "input-row": "cKz",
  "email-row": "cKa",
  "button-row": "cKH",
  "btn-edit": "cKO",
  "input-column": "cKm",
  "form-btn-row": "cKp",
  "border-bottom": "cKx",
  "avatar-input": "cKw"
};
