export default {
  "cell-content": "qcp",
  "icon": "qcx",
  "status_check-m": "qcw",
  "description": "qcN body-1",
  "from": "qcv body-2",
  "from--abort": "qco",
  "description--abort": "qcn",
  "title": "qci body-2"
};
