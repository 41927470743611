export default {
  "supplier-selector-wrapper": "qCL",
  "select-supplier": "qCT",
  "dropdown": "qCz",
  "iban": "qCa",
  "card": "qCH body-2",
  "add-btn": "qCO btn btn--square btn--icon-only",
  "archived-card": "qCm",
  "title": "qCp body-1",
  "archived-title": "qCx",
  "edit-supplier-tooltip": "qCw",
  "add-supplier": "qCN btn btn--tertiary",
  "error": "qCv caption"
};
