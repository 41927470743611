/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, race, rawTimeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import { INVOICE_STATUSES, SOURCE_TYPES } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesTableRowComponent extends Component {
  checkbox = Checkbox;

  @service abilities;
  @service intl;
  @service store;
  @service requestsManager;
  @service notifierManager;
  @service organizationManager;

  @tracked isRowActive = false;

  get showPendingInvoiceQuickActions() {
    return this.args.invoice.status === INVOICE_STATUSES.pending;
  }

  get requesterName() {
    let { requestTransfer } = this.args.invoice || {};

    if (requestTransfer?.initiator_id) {
      let { fullName } = this.store.peekRecord('membership', requestTransfer.initiator_id) || {};

      return fullName || '-';
    }
  }

  get supplierName() {
    let { supplierName, invoiceNumber, source, selfInvoiceId, status, id, supplierSnapshot } =
      this.args.invoice;

    let name = variation('feature--boolean-supplier-hub') ? supplierSnapshot.name : supplierName;

    let isItalian = this.organizationManager.organization?.isItalian;

    return {
      id: id || '-',
      status: status || '-',
      title: name || '-',
      subtitle: invoiceNumber || '-',
      missing: !name,
      isEInvoice: source === SOURCE_TYPES.E_INVOICING && isItalian,
      hasSelfInvoice: selfInvoiceId,
    };
  }

  get totalAmount() {
    let { totalAmount } = this.args.invoice;
    return {
      title: this.intl.formatMoney(totalAmount?.value, {
        currency: totalAmount?.currency,
        signus: '-',
      }),
      missing: !totalAmount || !totalAmount.value,
    };
  }

  get showInvoiceSelector() {
    return (
      this.args.status === INVOICE_STATUSES.toReview &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get isSupplierInvoiceReviewable() {
    let { invoice, status } = this.args;
    let { selfInvoiceId } = invoice;

    return status === INVOICE_STATUSES.toReview && !selfInvoiceId;
  }

  get specificCells() {
    let { invoice, status } = this.args;
    let {
      fileName,
      dueDate,
      scheduledDate,
      paymentDate,
      status: invoiceStatus,
      hasDuplicates,
      isAttachmentNonFinancial,
    } = invoice;

    let paidAndArchived;

    paidAndArchived = {
      paymentDate:
        invoiceStatus === INVOICE_STATUSES.archived
          ? '-'
          : dateToken({
              date: paymentDate,
              locale: this.intl.primaryLocale,
              token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            }),
      status: invoiceStatus,
    };

    let cellTypes = {
      to_review: {
        fileName: {
          fileName,
          ...(variation('feature-non-valid-documents') &&
          this.abilities.can('review supplier-invoice') &&
          this.isSupplierInvoiceReviewable
            ? { hasDuplicates, isAttachmentNonFinancial }
            : {}),
        },
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
        },
      },
      pending: {
        ...(this.abilities.can('load transfer request') ? { requester: this.requesterName } : {}),
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
        },
      },
      scheduled: {
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          missing: !dueDate,
        },
        scheduledDate: scheduledDate
          ? dateToken({
              date: scheduledDate,
              locale: this.intl.primaryLocale,
              token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            })
          : '-',
      },
      paid: paidAndArchived,
      archived: paidAndArchived,
    };

    return cellTypes[status?.split(',')[0]];
  }

  @action
  toggleRowState(boolean) {
    this.isRowActive = boolean;
  }

  approveRequestTask = dropTask(async ({ supplierInvoice, bankAccount }) => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    request.bankAccount = bankAccount;
    await this.requestsManager.quickApproveTransferRequestTask.perform(request);
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.SCHEDULED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });

  rejectRequestTask = dropTask(async supplierInvoice => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    await this.requestsManager.quickRejectTransferRequestTask.perform(request);
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });
}
