export default {
  "wrapper": "qEz",
  "header": "qEa mb-48",
  "form-title": "qEH title-2 mb-16",
  "form-subtitle": "qEO body-2",
  "form": "qEm",
  "section": "qEp mb-48",
  "section-title": "qEx title-3 mb-16",
  "form-footer": "qEw",
  "footer-btn": "qEN",
  "transfer-form": "qEv"
};
