export default {
  "wrapper": "cva",
  "per-page-container": "cvH",
  "options": "cvO",
  "option": "cvm",
  "active": "cvp",
  "description": "cvx",
  "description-per-page": "cvw",
  "summary": "cvN"
};
