export default {
  "filters-checkbox": "qLH mb-8",
  "csv-options-button": "qLO",
  "active": "qLm",
  "options-format-settings-wrapper": "qLp",
  "checkbox": "qLx",
  "options-format-settings": "qLw",
  "hidden": "qLN",
  "visible": "qLv",
  "disabled": "qLo",
  "buttons": "qLn"
};
