export default {
  "attachment-viewer": "qqi",
  "hide-sidebar": "qqA",
  "sidebar": "qqY",
  "header": "qqh",
  "header-top": "qqf",
  "header-icon": "qqK",
  "header-amount": "qqG",
  "infobox": "qqr",
  "vat": "qqb",
  "validation": "qqy"
};
