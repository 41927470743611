export default {
  "container": "cpY",
  "label": "cph",
  "disabled": "cpf",
  "small": "cpK caption",
  "large": "cpG body-2",
  "caption": "cpr",
  "amount": "cpb",
  "right-aligned": "cpy",
  "expanded": "cpU",
  "amount--current": "cpj",
  "amount--total": "cpW",
  "amount--sep": "cpu"
};
