export default {
  "savings-account": "qdN",
  "closed": "qdv",
  "badge": "qdo",
  "subtitle": "qdn",
  "amount": "qdi",
  "processing": "qdA",
  "gain": "qdY",
  "active": "qdh",
  "progress": "qdf",
  "progress-bar": "qdK",
  "progress-text": "qdG",
  "info": "qdr",
  "menu-item": "qdb body-2",
  "close-account": "qdy"
};
