/* import __COLOCATED_TEMPLATE__ from './step-state.hbs'; */
import Component from '@glimmer/component';

export default class StepState extends Component {
  get shouldDisplayResolutionIcon() {
    let { isRejectedStep, isSuccessStep } = this.args;
    return isRejectedStep || isSuccessStep;
  }
  get resolutionStepIcon() {
    let { isRejectedStep, isSuccessStep } = this.args;

    if (isSuccessStep) {
      return {
        icon: 'icon_checkmark_rounded_filled',
      };
    }
    if (isRejectedStep) {
      return {
        icon: 'icon_cross_rounded_filled',
      };
    }
  }
}
