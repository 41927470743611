/* import __COLOCATED_TEMPLATE__ from './placeholder-v2.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { TABS } from 'qonto/constants/supplier-invoice';

export default class PlaceholderComponent extends Component {
  @service abilities;

  // This doesn't include the first an last columns
  // that are always shown
  #numberOfPlaceholderLinesByTab = {
    [TABS.ALL_INVOICES]: 4,
    [TABS.TO_PAY]: 1,
    [TABS.SCHEDULED]: 2,
    [TABS.COMPLETED]: 3,
    [TABS.TASKS]: this.abilities.can('load transfer request') ? 2 : 1,
  };

  get columns() {
    return new Array(this.#numberOfPlaceholderLinesByTab[this.args.tab]);
  }
}
