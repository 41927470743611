export default {
  "wrapper": "cmZ",
  "header": "cmR",
  "fadeIn": "cmQ",
  "header-content": "cme",
  "with-cta": "cmB",
  "header-title": "cmE title-4",
  "advanced-filters-trigger": "cmd",
  "filters-button": "cmI",
  "filters-button-upsell": "cmt",
  "filters-upsell-item": "cmM",
  "filters-badge": "cmP",
  "chart-container": "cml",
  "chart-legend-container": "cmX mb-24",
  "chart-watermark-container": "cmC",
  "chart-watermark": "cmk",
  "placeholder-title": "cmJ",
  "placeholder-subtitle": "cms",
  "advanced-filters": "cmF"
};
