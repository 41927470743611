export default {
  "sidebar": "che",
  "body": "chB",
  "form": "chE",
  "form-section": "chd",
  "form-title": "chI title2",
  "row": "cht",
  "payment-details": "chM",
  "col": "chP",
  "footer": "chl",
  "footer-button": "chX"
};
