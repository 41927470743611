export default {
  "account-description": "cxo",
  "account-identification": "cxn",
  "account-avatar": "cxi",
  "account-name": "cxA",
  "account-balance": "cxY body-2",
  "status": "cxh",
  "external-accounts-disclaimer": "cxf",
  "failed-connection": "cxK"
};
