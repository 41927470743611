/* import __COLOCATED_TEMPLATE__ from './cashflow-jumbo.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import { buildCashflowTimevalue } from 'qonto/utils/chart/display';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import CashflowComponent from './cashflow';

export default class CashflowJumboComponent extends CashflowComponent {
  @service abilities;

  get maxDisplayedMonths() {
    return this.args.maxDisplayedMonths;
  }

  get indexOffset() {
    return this.args.indexOffset;
  }

  get balance() {
    if (!this.timeseries) return;

    return this.timeseries.at(-1).data.endingBalance;
  }

  get cashflowTimeseries() {
    if (this.args.isLoading || this.args.isUpdating || !this.statistics.base) return;

    if (this.abilities.cannot('assign category')) return this.timeseries;

    let [inflows, outflows, balances] = this.statistics.base;
    let baseArray = Array.from({ length: balances.length });

    return baseArray.map((_, index) => {
      return buildCashflowTimevalue(
        inflows[index],
        outflows[index],
        balances[index],
        this.timezone
      );
    });
  }

  /**
   * Displayed timeseries
   */
  get chartTimeseries() {
    if (!this.cashflowTimeseries) return;

    let offset = this.indexOffset;
    let cutoff = offset + this.maxDisplayedMonths;
    let chartTimeseries = [...this.cashflowTimeseries].slice(offset, cutoff);

    return chartTimeseries;
  }

  /**
   * Chart timeseries + next available timevalue
   */
  get balanceTimeseries() {
    if (!this.cashflowTimeseries) return;

    let timeseriesLastIndex = this.cashflowTimeseries.length - 1;
    let cutoff = this.indexOffset + this.maxDisplayedMonths;
    let lastBalanceIndex = cutoff < timeseriesLastIndex ? cutoff + 1 : timeseriesLastIndex;
    let lastBalancePoint = this.cashflowTimeseries.at(lastBalanceIndex);

    let balanceTimeseries = [...this.chartTimeseries, lastBalancePoint];

    return balanceTimeseries;
  }

  constructor() {
    super(...arguments);
    this.storageKey = `cashflow-jumbo-period-${this.membershipId}`;
  }

  @action
  onSavePeriod(period, closeDropdown) {
    this.args.onResetOffset();

    closeDropdown?.();
    if (!period) return;

    this._selectedPeriod = this._formatPeriod(period);
    this.persistPeriod(period);

    this.segment.track('cash-flow_filter_clicked', {
      filtertype: 'period',
    });
    this.refreshWidgetTask.perform(period).catch(ignoreCancelation);
  }
}
