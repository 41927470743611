export default {
  "qonto-pilot-modal": "coS",
  "content": "coc",
  "header": "coq",
  "transition-state-container": "coZ",
  "lottie": "coR",
  "prompt-input": "coQ",
  "buttons": "coe",
  "footer": "coB caption",
  "divider": "coE",
  "illustration": "cod",
  "icon": "coI",
  "quick-actions-container": "cot",
  "action": "coM body-2",
  "action-title": "coP",
  "disabled": "col",
  "action-tag": "coX caption-bold",
  "hotkey": "coC body-1",
  "footer-action": "cok",
  "grow": "coJ",
  "footer-feedback-link": "cos",
  "buttons-container": "coF",
  "transfer-details-container": "cog",
  "error-container": "coD",
  "option-iban": "coV",
  "actions": "coL",
  "title": "coT",
  "group-title": "coz caption-bold",
  "beta-badge": "coa",
  "hotkeys": "coH",
  "supplier-invoices-dropzone": "coO",
  "success-icon": "com",
  "error-icon": "cop",
  "file-items": "cox",
  "file-item": "cow",
  "uploader-file": "coN",
  "transactions-table-container": "cov",
  "transactions-table": "coo",
  "transaction": "con",
  "prompt-example": "coi"
};
