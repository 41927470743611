export default {
  "details": "qXT",
  "details-header": "qXz",
  "details-content": "qXa",
  "details-footer": "qXH",
  "invoice-status-container": "qXO body-2",
  "invoice-dates": "qXm",
  "overdue": "qXp",
  "details-list": "qXx",
  "details-list-item": "qXw",
  "details-list-item-label": "qXN body-2",
  "details-list-item-value": "qXv",
  "border-top": "qXo",
  "description": "qXn",
  "description-value": "qXi",
  "edit-button": "qXA body-2",
  "edit-icon": "qXY",
  "badge-union": "qXh",
  "edit-description": "qXf"
};
