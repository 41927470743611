export default {
  "label-select": "qPF",
  "puce": "qPg",
  "puce-wrapper": "qPD",
  "puce-container": "qPV",
  "readonly": "qPL",
  "label-display": "qPT",
  "label-list-name": "qPz body-1",
  "label-list-value": "qPa body-2"
};
