export default {
  "section-container": "cbb",
  "disclaimer-section": "cby",
  "trip-title": "cbU caption-bold mb-16",
  "google-link-container": "cbj",
  "link-icon": "cbW",
  "trip-details": "cbu body-2",
  "trip-details-icon": "cyS",
  "trip-details-title": "cyc",
  "subtitle": "cyq caption-bold mb-16",
  "disclaimer": "cyZ",
  "visualizer": "cyR"
};
