/* import __COLOCATED_TEMPLATE__ from './cockpit.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import {
  DUE_DATE_FILTER,
  INSIGHT_LABELS,
  INSIGHTS_ANIMATION_DURATION,
  INSIGHTS_PREFERENCE_STORAGE_KEY,
  INVOICE_STATUSES,
  STATUS_COLORS,
  TABS,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import {
  getDueInvoicesInsightSubtitle,
  getUpcomingInvoicesInsightSubtitle,
} from 'qonto/utils/supplier-invoices';

export default class SupplierInvoiceCockpit extends Component {
  @service intl;
  @service segment;

  @tracked customInsight =
    safeLocalStorage.getItem(INSIGHTS_PREFERENCE_STORAGE_KEY) ?? INSIGHT_LABELS.paidAmount;

  colors = STATUS_COLORS;

  get insights() {
    return this.args.currentInsights ?? this.args.previousInsights;
  }

  get titles() {
    return {
      [INSIGHT_LABELS.totalDueAmount]: this.intl.t('supplier-invoices.cockpit.to-pay-today.title'),
      [INSIGHT_LABELS.totalUpcomingAmount]: this.intl.t('supplier-invoices.cockpit.upcoming.title'),
      [INSIGHT_LABELS.paidAmount]: this.intl.t('supplier-invoices.cockpit.paid.title'),
      [INSIGHT_LABELS.scheduledAmount]: this.intl.t('supplier-invoices.cockpit.processing.title'),
      [INSIGHT_LABELS.inboxAmount]: this.intl.t('supplier-invoices.cockpit.imported.title'),
      [INSIGHT_LABELS.pendingAmount]: this.intl.t('supplier-invoices.cockpit.pending.title'),
    };
  }

  get subtitles() {
    return {
      [INSIGHT_LABELS.totalDueAmount]: getDueInvoicesInsightSubtitle(this.insights, this.intl),
      [INSIGHT_LABELS.totalUpcomingAmount]: getUpcomingInvoicesInsightSubtitle(
        this.insights,
        this.intl
      ),
      [INSIGHT_LABELS.paidAmount]: this.intl.t('supplier-invoices.cockpit.paid-label.days'),
      [INSIGHT_LABELS.scheduledAmount]: this.intl.t('supplier-invoices.cockpit.others.subtitle'),
      [INSIGHT_LABELS.inboxAmount]: this.intl.t('supplier-invoices.cockpit.others.subtitle'),
      [INSIGHT_LABELS.pendingAmount]: this.intl.t('supplier-invoices.cockpit.others.subtitle'),
    };
  }

  get delays() {
    let { previousInsights = {}, currentInsights = {}, labels } = this.args;

    let cumulatedDelay = 0;
    let hasChanges = label => {
      if (currentInsights[label] === 0 && previousInsights[label] === undefined) {
        return false;
      }

      return currentInsights[label] !== previousInsights[label];
    };

    return labels.map((label, index) => {
      if (hasChanges(label) && index !== 0) cumulatedDelay += INSIGHTS_ANIMATION_DURATION;
      return cumulatedDelay;
    });
  }

  get missingAmounts() {
    return {
      [INSIGHT_LABELS.totalDueAmount]: this.insights[INSIGHT_LABELS.totalDueMissingAmmountCounter],
      [INSIGHT_LABELS.totalUpcomingAmount]:
        this.insights[INSIGHT_LABELS.totalUpcomingMissingAmountCounter],
    };
  }

  get showOverdueWarning() {
    return {
      [INSIGHT_LABELS.totalDueAmount]: Boolean(this.insights[INSIGHT_LABELS.totalOverdueCounter]),
    };
  }

  get customInsights() {
    return [
      INSIGHT_LABELS.paidAmount,
      INSIGHT_LABELS.scheduledAmount,
      INSIGHT_LABELS.inboxAmount,
      INSIGHT_LABELS.pendingAmount,
    ];
  }

  get redirects() {
    return {
      [INSIGHT_LABELS.totalDueAmount]: {
        dueDate: DUE_DATE_FILTER.PAST_AND_TODAY,
        ...(variation('feature--boolean-improve-si-table')
          ? { tab: TABS.TO_PAY }
          : { status: INVOICE_STATUSES.toReview }),
      },
      [INSIGHT_LABELS.totalUpcomingAmount]: {
        dueDate: DUE_DATE_FILTER.FUTURE,
        ...(variation('feature--boolean-improve-si-table')
          ? { tab: TABS.TO_PAY }
          : { status: INVOICE_STATUSES.toReview }),
      },
    };
  }

  @action
  updateCustomInsight(option) {
    let eventPropsMap = {
      paid_last_30_days: 'paid',
      scheduled: 'processing',
      inbox: 'imported',
      pending: 'pending',
    };
    this.segment.track('supplier-invoices_cockpit-card_select', {
      data_type: eventPropsMap[option],
    });
    this.customInsight = option;
    safeLocalStorage.setItem(INSIGHTS_PREFERENCE_STORAGE_KEY, option);
  }
}
