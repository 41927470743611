export default {
  "card-details-container": "qqQ",
  "title": "qqe caption-bold mb-16",
  "section": "qqB",
  "divider": "qqE",
  "top-divider": "qqd",
  "content": "qqI body-2",
  "description": "qqt",
  "justify-end": "qqM",
  "icon": "qqP ml-8"
};
