export default {
  "wrapper": "qct",
  "request-header": "qcM",
  "request": "qcP",
  "scheduled": "qcl",
  "scheduled-label": "qcX",
  "note": "qcC",
  "note-label": "qck",
  "note-label-danger": "qcJ",
  "note-content": "qcs",
  "status": "qcF",
  "transfer-amount": "qcg"
};
