export default {
  "numbering-container": "cAq",
  "automatic-number-label": "cAZ",
  "icon-tooltip": "cAR",
  "fix-width": "cAQ",
  "dates-container": "cAe",
  "with-performance-date": "cAB",
  "date-picker": "cAE",
  "label": "cAd",
  "header-text-field-container": "cAI",
  "mt-16": "cAt",
  "header-text-field": "cAM",
  "header-text-field-close-button": "cAP"
};
