export default {
  "account-container": "cpM",
  "badge": "cpP",
  "subtitle": "cpl title-4",
  "accounts-filter": "cpX",
  "account-filter-label": "cpC",
  "status": "cpk",
  "account-info": "cpJ",
  "balance": "cps",
  "revealed-balance": "cpF",
  "hidden-balance": "cpg"
};
