export default {
  "container": "qTJ",
  "item": "qTs caption-bold",
  "hover": "qTF",
  "active": "qTg",
  "name-wrapper": "qTD",
  "name": "qTV body-2",
  "count": "qTL",
  "actions-placeholder": "qTT",
  "actions": "qTz",
  "show": "qTa",
  "action-button": "qTH"
};
