export default {
  "header-cell": "cKA",
  "empty": "cKY",
  "header-content": "cKh caption-bold",
  "active": "cKf",
  "align-right": "cKK",
  "col-8": "cKG",
  "col-7": "cKr",
  "col-5": "cKb",
  "col-4": "cKy"
};
