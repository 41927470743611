export default {
  "item": "cfv",
  "details": "cfo",
  "flex": "cfn",
  "header": "cfi cfn",
  "flex-no-gap": "cfA cfn",
  "file-name": "cfY",
  "label": "cfh body-2",
  "self-icon": "cff",
  "with-separator": "cfK",
  "dimmed": "cfG"
};
