export default {
  "bullet": "cOR",
  "slice-0": "cOQ",
  "slice-1": "cOe",
  "slice-2": "cOB",
  "slice-3": "cOE",
  "slice-4": "cOd",
  "slice-5": "cOI",
  "slice-6": "cOt",
  "slice-other": "cOM",
  "slice-uncategorized": "cOP",
  "label": "cOl",
  "ellipsis": "cOX",
  "rest": "cOC",
  "previous-date-range": "cOk caption"
};
