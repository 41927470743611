/* import __COLOCATED_TEMPLATE__ from './products-and-services.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvoiceSubscriptionsFormProductsAndServicesComponent extends Component {
  @service organizationManager;
  @service productsCatalog;
  @service modals;
  @service intl;
  @service segment;

  @reads('organizationManager.organization') organization;
  @reads('args.document.currency', CURRENCIES.default) currency;

  @tracked hasMigrationDismissalFailed = false;

  get showMigrationPrompt() {
    return (
      variation('feature-invoices-catalog-of-items') &&
      this.args.documentItems?.length > 0 &&
      this.productsCatalog.isMigrationPending &&
      !this.hasMigrationDismissalFailed
    );
  }

  get documentItems() {
    return (this.args.documentItems ?? []).filter(({ currency }) => currency === this.currency);
  }

  @action
  addItem(invoicingDocument) {
    invoicingDocument.items.createRecord(
      defaultValues(
        this,
        invoicingDocument.items[0]?.vatRate,
        invoicingDocument.items[0]?.vatExemptionCode
      )
    );

    // fill the current new item unit with the last item's unit
    let currentIndex = invoicingDocument.items.length - 1;
    invoicingDocument.items[currentIndex].unit = invoicingDocument.items[currentIndex - 1]?.unit;
  }

  @action
  deleteItem(item) {
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }
  }

  @action
  onNotNow() {
    this.dismissMigrationTask.perform().catch(ignoreCancelation);
  }

  @action
  onMigrate() {
    this.segment.track('catalog_migration_started', {
      source: 'document-creation',
    });
    this.modals.open('products/migration-modal', {
      documentItems: this.args.documentItems,
      isFullScreenModal: true,
      onSuccess: source => this.onMigrationSuccessTask.perform(source),
      source: 'document-creation',
    });
  }

  onMigrationSuccessTask = dropTask(async source => {
    await this.productsCatalog.fetchMigrationStatusTask.perform();
    this.segment.track('catalog_migration_completed', {
      source,
    });
    await this.productsCatalog.fetchProductsTask.perform().catch(ignoreCancelation);
  });

  dismissMigrationTask = dropTask(async () => {
    try {
      await this.productsCatalog.bulkCreateProductsTask.perform([]);
      await this.productsCatalog.fetchMigrationStatusTask.perform();
      this.segment.track('catalog_migration_discarded', {
        source: 'document-creation',
      });
    } catch {
      this.hasMigrationDismissalFailed = true;
    }
  });
}
