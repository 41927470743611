export default {
  "row": "qep",
  "active": "qex",
  "dot": "qew",
  "red": "qeN",
  "green": "qev",
  "yellow": "qeo",
  "status": "qen",
  "align-right": "qei",
  "animated": "qeA",
  "fadein-item": "qeY",
  "cell": "qeh body-2",
  "cell-content": "qef",
  "amount": "qeK body-1",
  "subtitle": "qeG caption",
  "no-padding": "qer"
};
