export default {
  "referral-invite": "crS",
  "referral": "crc",
  "illustration": "crq",
  "steps": "crZ",
  "step": "crR",
  "share-referral": "crQ",
  "choice": "cre",
  "line": "crB",
  "email": "crE",
  "email-input": "crd",
  "input-field--error": "crI",
  "error-message": "crt",
  "external-link-icon": "crM"
};
