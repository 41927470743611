export default {
  "sidebar-header": "qzm",
  "sidebar-header__top": "qzp",
  "sidebar-header__body": "qzx",
  "sidebar-header__content": "qzw",
  "sidebar-header__date": "qzN",
  "infobox": "qzv",
  "infobox--link": "qzo",
  "ghost": "qzn",
  "fail": "qzi",
  "r-transaction-disclaimer": "qzA",
  "hold-disclaimer": "qzY",
  "transaction-link": "qzh body-2",
  "icon-link": "qzf"
};
