/* import __COLOCATED_TEMPLATE__ from './beneficiaries-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

export default class RequestTransferBeneficiarySidebarBeneficiariesListComponent extends Component {
  @service segment;
  @service intl;
  @service toastFlashMessages;
  @service modals;

  @action
  handleClick() {
    this.segment.track('transfer_rolodex_create_beneficiary_click');
    this.args.addOrEditBeneficiary(null);
  }

  @action
  confirmBeneficiaryDelete(beneficiary) {
    this.segment.track('transfer_rolodex_delete_beneficiary_click');
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.delete-beneficiary.title'),
      description: this.intl.t('transfers.modals.delete-beneficiary.desc'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  deleteBeneficiaryTask = task(async (close, { beneficiary }) => {
    try {
      this.segment.track('transfer_rolodex_delete_beneficiary_confirmed');

      beneficiary.deleteRecord();
      await beneficiary.save();

      close();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.delete.success-message')
      );
      this.args.onBeneficiaryDeleted?.();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
