export default {
  "row": "cNW",
  "cell": "cNu",
  "title": "cvS",
  "internal-note": "cvc",
  "unit": "cvq",
  "price": "cvZ",
  "quick-actions": "cvR",
  "missing-details-container": "cvQ",
  "missing-details-label": "cve body-2",
  "warning-icon": "cvB",
  "empty": "cvE",
  "checkbox": "cvd",
  "align-checkbox": "cvI"
};
