export default {
  "modal-wrapper": "cfr",
  "spinner-container": "cfb",
  "spinner": "cfy",
  "container": "cfU",
  "tabs-item": "cfj",
  "form-container": "cfW",
  "fieldset": "cfu",
  "preview-container": "cKS",
  "switch-container": "cKc",
  "pdf-preview-container": "cKq",
  "form-content": "cKZ",
  "row": "cKR",
  "numbering-format": "cKQ",
  "numbering-fields": "cKe",
  "input-settings": "cKB",
  "next-invoice-number": "cKE",
  "spacing": "cKd",
  "label-tooltip": "cKI",
  "tooltip-icon": "cKt",
  "customize-field": "cKM",
  "button-container-footer": "cKP"
};
