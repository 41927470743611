export default {
  "credit-note-sidebar": "cnd",
  "body": "cnI",
  "box": "cnt",
  "box-header": "cnM",
  "footer": "cnP",
  "footer-button": "cnl",
  "sidebar-box": "cnX",
  "border-top": "cnC",
  "row": "cnk",
  "related-invoice-title": "cnJ caption-bold",
  "einvoice-rejected": "cns"
};
