export default {
  "header": "qlh",
  "subtitle": "qlf body-2",
  "date": "qlK",
  "subtitle-error": "qlG",
  "card": "qlr",
  "card-header": "qlb",
  "line-placeholder": "qly",
  "post-closing-list": "qlU",
  "post-closing-container": "qlj",
  "post-closing-list-element": "qlW",
  "free-trial-disclaimer": "qlu",
  "description": "qXS body-2",
  "mw-100": "qXc",
  "addon-overview": "qXq",
  "addons-container": "qXZ"
};
