/* import __COLOCATED_TEMPLATE__ from './fiscal-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import ITALIAN_WELFARE_FUNDS from 'qonto/constants/it-welfare-fund';
import ITALIAN_WITHHOLDING_REASONS from 'qonto/constants/it-withholding-reason';
import ITALIAN_WITHHOLDING_TYPES from 'qonto/constants/it-withholding-type';
import { IRPF_ES_RATES } from 'qonto/constants/receivable-invoice';

export default class ReceivableInvoicesFormFiscalDetailsComponent extends Component {
  @service intl;
  @service organizationManager;

  @reads('organizationManager.organization.isItalian') isItalianOrganization;
  @reads('organizationManager.organization.isSpanish') isSpanishOrganization;

  @tracked hasPension = Boolean(this.args.document.welfareFund?.type || this.welfareFundRate);
  @tracked hasWithholdingTax = Boolean(
    this.args.document.withholdingTax?.type ||
      this.withholdingTaxRate ||
      this.selectedWithholdingPaymentReason
  );
  @tracked hasStampRevenue =
    Boolean(this.args.document.stampDutyAmount) && this.args.document.stampDutyAmount !== '0.00';

  amountField = AmountField;

  tempWithholdingTaxRate;
  tempWelfareFundRate;

  @cached
  get welfareFunds() {
    return Object.keys(ITALIAN_WELFARE_FUNDS).map(key => {
      return { key, label: `${key} - ${ITALIAN_WELFARE_FUNDS[key]}` };
    });
  }

  @cached
  get withholdingTypes() {
    return Object.keys(ITALIAN_WITHHOLDING_TYPES).map(key => {
      return { key, label: `${key} - ${ITALIAN_WITHHOLDING_TYPES[key]}` };
    });
  }

  @cached
  get withholdingReasons() {
    return Object.keys(ITALIAN_WITHHOLDING_REASONS).map(key => {
      return { key, label: `${key} - ${ITALIAN_WITHHOLDING_REASONS[key]}` };
    });
  }

  @cached
  get withholdingTaxRates() {
    return IRPF_ES_RATES;
  }

  get selectedWelfareFund() {
    return this.welfareFunds.find(
      welfareFund => welfareFund.key === this.args.document.welfareFund?.type
    );
  }

  get selectedWithholdingType() {
    return this.withholdingTypes.find(
      withholdingType => withholdingType.key === this.args.document.withholdingTax?.type
    );
  }

  get selectedWithholdingPaymentReason() {
    return this.withholdingReasons.find(
      withholdingReason => withholdingReason.key === this.args.document.withholdingTax?.reason
    );
  }

  get selectedWithholdingTaxRate() {
    return this.withholdingTaxRates.find(
      withholdingTaxRate => withholdingTaxRate.value === this.args.document.withholdingTax?.rate
    );
  }

  get welfareFundRate() {
    if (this.tempWelfareFundRate && this.args.document.welfareFund?.rate) {
      return this.tempWelfareFundRate;
    } else if (this.args.document.welfareFund?.rate) {
      return this._calculatePreciseRate(this.args.document.welfareFund?.rate);
    }
    return null;
  }

  get withholdingTaxRate() {
    if (this.tempWithholdingTaxRate && this.args.document.withholdingTax?.rate) {
      return this.tempWithholdingTaxRate;
    } else if (this.args.document.withholdingTax?.rate) {
      return this._calculatePreciseRate(this.args.document.withholdingTax?.rate);
    }
    return null;
  }

  get welfareFundRateError() {
    let error = this.args.document.welfareFund.get('errors.rate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.fiscal-details.rate.error');
      default:
        return null;
    }
  }

  get withholdingTaxRateError() {
    let error = this.args.document.withholdingTax.get('errors.rate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.fiscal-details.rate.error');
      default:
        return null;
    }
  }

  @action updateSelectedWithholdingTaxRate(rate) {
    this.args.document.withholdingTax.rate = rate.value;
  }

  @action updateWelfareFund({ key }) {
    this.args.document.welfareFund.type = key;
  }

  @action updateWithholdingType({ key }) {
    this.args.document.withholdingTax.type = key;
  }

  @action updateWithholdingPaymentReason({ key }) {
    this.args.document.withholdingTax.reason = key;
  }

  @action updateWelfareFundRate(rate) {
    this.tempWelfareFundRate = rate;
    let tempRate = parseFloat(rate.replace(',', '.')) / 100;
    if (tempRate >= 0) {
      this.args.document.welfareFund.rate = parseFloat(tempRate.toFixed(4)).toString();
    } else {
      this.args.document.welfareFund.rate = '0.00';
    }

    if (this.args.document.welfareFund.rate > 1) {
      this.args.document.welfareFund.errors.add('rate', 'invalid');
    }
  }

  @action updateWithholdingTaxRate(rate) {
    if (this.isSpanishOrganization) {
      this.args.document.withholdingTax.rate = rate.value;
    } else {
      this.tempWithholdingTaxRate = rate;
      let tempRate = parseFloat(rate.replace(',', '.')) / 100;
      if (tempRate >= 0) {
        this.args.document.withholdingTax.rate = parseFloat(tempRate.toFixed(4)).toString();
      } else {
        this.args.document.withholdingTax.rate = '0.00';
      }

      if (this.args.document.withholdingTax.rate > 1) {
        this.args.document.withholdingTax.errors.add('rate', 'invalid');
      }
    }
  }

  @action
  togglePension() {
    this.hasPension = !this.hasPension;
    if (!this.hasPension && !this.args.isPensionDisabled) {
      this.args.document.welfareFund.rate = undefined;
      this.args.document.welfareFund.type = undefined;
      this.tempWelfareFundRate = undefined;
    }
  }

  @action
  toggleWithholdingTax() {
    this.hasWithholdingTax = !this.hasWithholdingTax;
    if (!this.hasWithholdingTax && !this.args.isWithholdingTaxDisabled) {
      this.args.document.withholdingTax.type = undefined;
      this.args.document.withholdingTax.rate = undefined;
      this.tempWithholdingTaxRate = undefined;
      this.args.document.withholdingTax.reason = undefined;
    }
  }

  @action
  toggleStampRevenue() {
    this.hasStampRevenue = !this.hasStampRevenue;
    if (!this.hasStampRevenue) {
      this.args.document.stampDutyAmount = '';
    }
  }

  @action
  onAmountChange(amountValue) {
    this.args.document.set('stampDutyAmount', amountValue);
  }

  /*
  This is required to avoid imprecise float multiplication
  Example: 0.14 * 100 = 14.000000000000002
  With this function we are scaling floats to integers, doing the multiplication, 
  and transforming them back to floats
  */
  _calculatePreciseRate(value) {
    value = value.toString();
    if (!value.includes('.')) {
      return value * 100;
    }

    let length = value.split('.')[1].length;

    value *= Math.pow(10, length);

    return (Math.trunc(value) * 100) / Math.pow(10, length);
  }
}
