export default {
  "section-container": "cWq",
  "disclaimer-section": "cWZ",
  "trip-title": "cWR caption-bold mb-16",
  "google-link-container": "cWQ",
  "link-icon": "cWe",
  "trip-details": "cWB body-2",
  "trip-details-icon": "cWE",
  "trip-details-title": "cWd",
  "subtitle": "cWI caption-bold mb-16",
  "disclaimer": "cWt",
  "visualizer": "cWM"
};
