export default {
  "y-axis-placeholder": "caT",
  "y-axis-navigable": "caz",
  "y-axis-tick": "caa",
  "x-axis-placeholder": "caH",
  "x-axis-navigable": "caO",
  "x-axis-tick-wrapper": "cam",
  "x-axis-tick": "cap",
  "chart-loading-renderer": "cax"
};
