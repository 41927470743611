export default {
  "selector": "cir",
  "pension-selector-content": "cib",
  "withholding-tax-selector-content": "ciy",
  "withholding-tax-selector-content-row": "ciU",
  "power-select-field": "cij",
  "rate-field": "ciW",
  "stamp-revenue-field": "ciu",
  "dropdown-option": "cAS",
  "dropdown-content": "cAc"
};
