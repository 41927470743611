export default {
  "row": "qaK",
  "item-bulk": "qaG",
  "active": "qar",
  "disabled": "qab",
  "cell": "qay body-2",
  "not-disabled": "qaU",
  "item-amount-disabled": "qaj",
  "item-amount-credit": "qaW",
  "no-padding": "qau",
  "cell-content": "qHS",
  "centered-cell": "qHc",
  "item-counterparty-icon": "qHq mr-16",
  "avatar": "qHZ",
  "item-counterparty-name": "qHR",
  "item-counterparty-name-status": "qHQ",
  "disputing-chargeback": "qHe",
  "item-counterparty-name-text": "qHB",
  "item-amount": "qHE body-1",
  "item-amount-foreign": "qHd",
  "attachment-icon": "qHI",
  "item-method": "qHt",
  "bank-account-avatar": "qHM",
  "attachment-status-dot": "qHP",
  "attached": "qHl",
  "missing": "qHX",
  "item-attachment": "qHC",
  "compact": "qHk",
  "fadein-item": "qHJ"
};
