export default {
  "details-box": "cTw",
  "picto": "cTN",
  "picto-status": "cTv",
  "ghost": "cTo",
  "primary-info": "cTn",
  "date": "cTi",
  "account-infobox": "cTA",
  "declined-disclaimer": "cTY",
  "nrc-download": "cTh"
};
