export default {
  "sections": "qEq",
  "actions": "qEZ",
  "search-input": "qER",
  "no-upload": "qEQ",
  "new-link": "qEe body-2",
  "between-links": "qEB",
  "action-bar": "qEE",
  "empty-state": "qEd"
};
