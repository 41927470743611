export default {
  "attachment-viewer": "cUj",
  "hide-sidebar": "cUW",
  "sidebar": "cUu",
  "header": "cjS",
  "header-top": "cjc",
  "header-icon": "cjq",
  "header-amount": "cjZ",
  "infobox": "cjR",
  "vat": "cjQ",
  "validation": "cje"
};
