export default {
  "fullscreen-modal": "cNc",
  "creation-form-modal": "cNq",
  "links-container": "cNZ",
  "link-field": "cNR",
  "link": "cNQ",
  "add-link": "cNe",
  "remove-link": "cNB",
  "flex-row": "cNE",
  "flex-item": "cNd",
  "unit-trigger": "cNI",
  "dynamic-placeholders": "cNt"
};
