export default {
  "subtitle": "qSe",
  "green-text": "qSB",
  "balance-subtitle": "qSE",
  "options": "qSd",
  "shadow": "qSI",
  "menu-item": "qSt body-2",
  "close-account": "qSM",
  "footer": "qSP",
  "progress": "qSl",
  "progress-bar": "qSX",
  "progress-bar-text": "qSC",
  "progress-active": "qSk"
};
