export default {
  "sidepanel": "qJK",
  "close-icon": "qJG",
  "close-button": "qJr",
  "supplier-card": "qJb",
  "supplier-card-details": "qJy",
  "supplier-name": "qJU title-3 mb-8",
  "supplier-card-placeholder": "qJj",
  "box": "qJW",
  "box-element": "qJu",
  "related-invoices-title": "qsS",
  "placeholder-container": "qsc",
  "related-invoices-wrapper": "qsq",
  "related-invoice-placeholder": "qsZ",
  "related-invoice-placeholder-inner": "qsR",
  "actions": "qsQ",
  "currency": "qse"
};
