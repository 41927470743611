/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

export default class TransfersSepaSettlementSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service organizationManager;
  @service router;

  @tracked isAnimationReady = false;

  get isKYBPending() {
    let isKYBPending = this.organizationManager.organization.kybPending;

    return isKYBPending;
  }

  get isKYCPending() {
    let isKYCPending = this.organizationManager.membership.kycPending;
    return isKYCPending;
  }

  get content() {
    let { isQontoBeneficiary, transfer } = this.args;

    if (transfer.operationType === 'pay_later') {
      return this._getContentForPayLaterTransfer(transfer);
    }

    if (transfer.instant) {
      return this._getContentForInstantTransfer(transfer);
    }

    if (this.isKYCPending) {
      return this._getContentForPendingKYC(transfer);
    }

    if (this.isKYBPending) {
      return this._getContentForPendingKYB(transfer);
    }

    if (isQontoBeneficiary) {
      return this._getContentForQontoBeneficiaryTransfer(transfer);
    }

    return this._getContentForSepaTransfer(transfer);
  }

  get lottiePath() {
    return this.shouldDisplayPendingLottie
      ? '/lotties/transfers/timeout.json'
      : '/lotties/success.json';
  }

  @cached
  get shouldDisplayPendingLottie() {
    let { isQontoBeneficiary, transfer } = this.args;
    let isWaitingForAMLCheck = isQontoBeneficiary && transfer.isPending && !transfer.wasScheduled;

    return this.isKYBPending || this.isKYCPending || isWaitingForAMLCheck;
  }

  @action
  displayLottie() {
    this.isAnimationReady = true;
  }

  _getContentForInstantTransfer(transfer) {
    let { amount_currency, beneficiary_name } = this._getTransferContent(transfer);
    return {
      title: this.intl.t('transfers.modals.instant.success.title'),
      description: this.intl.t('transfers.modals.instant.success.description', {
        beneficiary_name,
        amount_currency,
      }),
    };
  }

  _getContentForPayLaterTransfer(transfer) {
    let { beneficiary_name, amount_currency } = this._getTransferContent(transfer);

    let title = transfer.instant
      ? this.intl.t('transfers.modals.instant.success.title')
      : this.intl.t('transfers.modals.success.regular.title');

    return {
      title,
      description: this.intl.t('transfers.modals.sepa.success.pay-later.description', {
        beneficiary: beneficiary_name,
        amount: amount_currency,
      }),
    };
  }

  _getContentForPendingKYC() {
    return {
      title: this.intl.t('transfers.modals.success.kyc-pending.title'),
      description: this.intl.t('transfers.modals.success.kyc-pending.description'),
    };
  }

  _getContentForPendingKYB(transfer) {
    if (transfer.wasScheduled) {
      return {
        title: this.intl.t('transfers.modals.success.kyb-pending.scheduled.title'),
        description: this.intl.t('transfers.modals.success.kyb-pending.scheduled.description'),
      };
    }

    return {
      title: this.intl.t('transfers.modals.success.kyb-pending.title'),
      description: this.intl.t('transfers.modals.success.kyb-pending.description'),
    };
  }

  _getContentForQontoBeneficiaryTransfer(transfer) {
    let { amount_currency, beneficiary_name } = this._getTransferContent(transfer);

    if (transfer.wasScheduled) {
      return {
        title: this.intl.t('transfers.modals.success.scheduled.title'),
        description: this.intl.t('transfers.modals.success.scheduled.description'),
      };
    }

    if (transfer.isPending) {
      return {
        title: this.intl.t('transfers.modals.success.qonto-beneficiary.pending.title'),
        description: this.intl.t('transfers.modals.success.qonto-beneficiary.pending.description'),
      };
    }

    return {
      title: this.intl.t('transfers.modals.success.qonto-beneficiary.title'),
      description: this.intl.t('transfers.modals.success.qonto-beneficiary.description', {
        beneficiary_name,
        amount_currency,
      }),
    };
  }

  _getContentForSepaTransfer(transfer) {
    let { amount_currency, beneficiary_name } = this._getTransferContent(transfer);

    if (transfer.wasScheduled) {
      return {
        title: this.intl.t('transfers.modals.success.scheduled.title'),
        description: this.intl.t('transfers.modals.success.scheduled.description'),
      };
    }

    return {
      title: this.intl.t('transfers.modals.success.regular.title'),
      description: this.intl.t('transfers.modals.success.regular.description', {
        beneficiary_name,
        amount_currency,
      }),
    };
  }

  _getTransferContent(transfer) {
    let amount_currency = this.intl.formatMoney(transfer.amount, {
      currency: transfer.amountCurrency,
    });

    let beneficiary_name = transfer.get('beneficiary.name');

    return {
      amount_currency,
      beneficiary_name,
    };
  }
}
