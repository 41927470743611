export default {
  "document": "cfJ",
  "document-details": "cfs",
  "document-details-amount": "cfF body-2",
  "negative": "cfg",
  "positive": "cfD",
  "rejected": "cfV",
  "document-icon": "cfL",
  "document-details-link": "cfT body-link",
  "canceled": "cfz"
};
