export default {
  "bank-account-option": "qgH",
  "row": "qgO",
  "active": "qgm",
  "animated": "qgp",
  "fadein-item": "qgx",
  "cell": "qgw body-2",
  "cell-content": "qgN",
  "subtitle": "qgv caption",
  "no-padding": "qgo",
  "empty": "qgn",
  "note": "qgi",
  "cell-content-amount": "qgA body-1",
  "cell-content-receipt": "qgY body-1",
  "cell-quick-actions": "qgh",
  "account-selector": "qgf",
  "cell-type": "qgK"
};
