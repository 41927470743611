export default {
  "request-transfer-validation": "qEA",
  "account-selector": "qEY",
  "has-error": "qEh",
  "actions": "qEf",
  "action-btn": "qEK",
  "warnings": "qEG",
  "top-border": "qEr",
  "account-selector-subtitle": "qEb",
  "action-btn-right": "qEy",
  "funds-disclaimer": "qEU",
  "account-options": "qEj",
  "account-balance": "qEW",
  "request-multi-transfer-validation": "qEu",
  "wrapper": "qdS",
  "warning-message-row": "qdc",
  "multi-transfer-align": "qdq",
  "button-approve": "qdZ",
  "button-approve-spinner": "qdR",
  "button-decline": "qdQ"
};
