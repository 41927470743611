export default {
  "row": "qeb",
  "active": "qey",
  "animated": "qeU",
  "fadein-item": "qej",
  "cell": "qeW body-2",
  "cell-content": "qeu",
  "subtitle": "qBS caption",
  "no-padding": "qBc",
  "note": "qBq",
  "cell-amount": "qBZ",
  "cell-content-amount": "qBR body-1"
};
