export default {
  "card-header": "qzk",
  "counterparty-name": "qzJ",
  "pan-section": "qzs header-section body-2",
  "infobox": "qzF header-section",
  "see-card-button": "qzg",
  "card-identifier-section": "qzD",
  "card-icon": "qzV",
  "nickname": "qzL body-1",
  "pan": "qzT body-2"
};
