export default {
  "mileage-validation": "qRx",
  "actions": "qRw",
  "action-btn": "qRN",
  "approve-btn": "qRv",
  "request-mileage-validation": "qRo",
  "account-selector": "qRn",
  "has-error": "qRi",
  "funds-disclaimer": "qRA",
  "account-options": "qRY",
  "account-balance": "qRh",
  "warnings": "qRf"
};
