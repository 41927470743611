export default {
  "modal": "qVT",
  "section": "qVz",
  "header": "qVa",
  "title": "qVH",
  "icon": "qVO",
  "content": "qVm",
  "export-template-wrapper": "qVp",
  "export-template-radio": "qVx",
  "export-template-content": "qVw",
  "export-template-label": "qVN",
  "export-template-button": "qVv",
  "export-template-list": "qVo",
  "inactive": "qVn",
  "export-template-title": "qVi",
  "export-template-description": "qVA",
  "export-template-item-content": "qVY",
  "permission-disclaimer-container": "qVh",
  "export-template-footer": "qVf",
  "export-template-cta-container": "qVK",
  "upsell": "qVG",
  "upsell-section": "qVr",
  "_content": "qVb",
  "_cta": "qVy",
  "upsell-title": "qVU",
  "upsell-description": "qVj"
};
