export default {
  "header": "qRe",
  "header-type": "qRB",
  "close": "qRE",
  "close-icon": "qRd",
  "header-infos": "qRI",
  "title": "qRt body-1",
  "declined": "qRM",
  "canceled": "qRP",
  "approved": "qRl",
  "pending": "qRX",
  "date": "qRC body-2",
  "mileage-icon": "qRk",
  "distance-subtitle": "qRJ body-2",
  "link-icon": "qRs",
  "container": "qRF",
  "picto": "qRg",
  "general": "qRD",
  "amount": "qRV",
  "calculation-container": "qRL",
  "counterparty": "qRT body-2",
  "infobox": "qRz"
};
