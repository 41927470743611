export default {
  "item-wrapper": "qlp",
  "title-sub-wrapper": "qlx",
  "icon-title-wrapper": "qlw",
  "icon": "qlN",
  "icon-wrapper": "qlv",
  "title": "qlo body-1",
  "subtitle": "qln body-2",
  "amount": "qli body-1"
};
