/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

export default class SelectorCheckbox extends Component {
  checkbox = Checkbox;

  get isChecked() {
    return Boolean(this.args.selected.find(id => id === this.args.item.id));
  }

  @action
  check() {
    this.args.onSelect(this.args.item);
  }
}
