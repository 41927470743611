export default {
  "wrapper": "qIW",
  "content": "qIu",
  "title": "qtS",
  "aside": "qtc",
  "description": "qtq",
  "faq-link": "qtZ",
  "close-btn": "qtR",
  "container": "qtQ",
  "mandate-field-list": "qte",
  "mandate-field-item": "qtB",
  "mandate-field-item__dot": "qtE",
  "mandate-field-item__text": "qtd"
};
